<template>
  <v-container fluid v-if="active">
    <div class="d-flex flex-row">
      <v-list class="my-0 py-0">
        <v-list-item v-if="sharedData.selectedTemplate" three-line>
          <v-list-item-content>
            <v-list-item-subtitle class="caption"
              >{{ $t("report_type") }}:</v-list-item-subtitle
            >
            <v-list-item-title>{{
              $t(`template_name.${sharedData.selectedTemplate.name}`)
            }}</v-list-item-title>
            <v-list-item-subtitle>
              <!--{{ sharedData.selectedTemplate.description }}-->
              {{ $t(`template_desc.${sharedData.selectedTemplate.name}`) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="sharedData.dateStyle === 'exactDates'" two-line>
          <v-list-item-content>
            <v-list-item-subtitle
              class="caption"
              v-if="!value.referenceParameters.inputValues.endDate"
              >{{ $t("selected_date") }}:</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="caption"
              v-if="value.referenceParameters.inputValues.endDate"
              >{{ $t("selected_dates") }}:</v-list-item-subtitle
            >
            <v-list-item-title>
              {{
                value.referenceParameters.inputValues.startDate
                  | getDisplayDate("date")
              }}
              <span v-if="value.referenceParameters.inputValues.endDate">
                -
                {{
                  value.referenceParameters.inputValues.endDate
                    | getDisplayDate("date")
                }}
              </span>
            </v-list-item-title>
            <!-- <v-list-item-subtitle>
            {{ $t("timezone") }}:
            {{ value.referenceParameters.inputValues.timezone }}
          </v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="sharedData.dateStyle === 'timeFrame'" three-line>
          <v-list-item-content>
            <v-list-item-subtitle class="caption"
              >{{ $t("selected_timeframe") }}:</v-list-item-subtitle
            >
            <v-list-item-title
              >{{ $t("timeframe_start") }}:
              <GsReadableTimeFrame
                :data="
                  value.referenceParameters.inputValues.timeFrame.startDate
                "
            /></v-list-item-title>
            <v-list-item-title
              >{{ $t("duration") }}:
              <GsReadableTimeFrame
                :data="value.referenceParameters.inputValues.timeFrame.period"
                :duration="true"
            /></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="sharedData.convertedInputParams.timezone" two-line>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t("language") }}:</v-list-item-subtitle>
            <v-list-item-title>
              {{ $t(value.referenceParameters.inputValues.locale) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="sharedData.convertedInputParams.timezone" two-line>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t("timezone") }}:</v-list-item-subtitle>
            <v-list-item-title>
              {{ value.referenceParameters.inputValues.timezone }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedAssetsExtract" two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="caption"
              >{{ $t("selected_assets") }}:</v-list-item-subtitle
            >
            <v-list-item-title class="more-space">{{
              selectedAssetsExtract.groups
            }}</v-list-item-title>
            <v-list-item-title class="more-space">{{
              selectedAssetsExtract.items
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedDriversExtract" two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="caption"
              >{{ $t("selected_drivers") }}:</v-list-item-subtitle
            >
            <v-list-item-title class="more-space">{{
              selectedDriversExtract.groups
            }}</v-list-item-title>
            <v-list-item-title class="more-space">{{
              selectedDriversExtract.items
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedGeozonesExtract" two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="caption"
              >{{ $t("selected_geozones") }}:</v-list-item-subtitle
            >
            <v-list-item-title class="more-space">{{
              selectedGeozonesExtract.groups
            }}</v-list-item-title>
            <v-list-item-title class="more-space">{{
              selectedGeozonesExtract.items
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="my-0 py-0">
        <v-list-item v-if="sharedData.convertedInputParams.dailyTime" two-line>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t("dailyTime") }}:</v-list-item-subtitle>
            <v-list-item-title>
              {{ value.referenceParameters.inputValues.dailyTime }}
            </v-list-item-title>
            <v-list-item-title
              v-if="value.referenceParameters.inputValues.effectiveWork"
            >
              {{ $t("effectiveWork") }}!
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="sharedData.convertedInputParams.calendarType"
          two-line
        >
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t("workDays") }}:</v-list-item-subtitle>
            <v-list-item-title>
              <template
                v-if="sharedData.convertedInputParams.calendarType === 1"
              >
                {{ value.referenceParameters.inputValues.calendar }}
              </template>
              <template v-else>
                {{
                  getDayNames(value.referenceParameters.inputValues.normalWork)
                }}
              </template>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="sharedData.needScheduling" three-line>
          <v-list-item-content>
            <v-list-item-subtitle class="caption"
              >{{ $t("scheduling") }}:</v-list-item-subtitle
            >
            <v-list-item-title>{{ value.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ value.description }}</v-list-item-subtitle>
            <v-list-item-subtitle
              >{{ value.startTime | getDisplayDate }} -
              {{ value.endTime | getDisplayDate }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              >{{ $t("repeats") }}:
              <GsCronjobToReadable :cronObject="schedulingTimeData"
            /></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="value.referenceParameters.notificationValues"
          two-line
        >
          <v-list-item-content>
            <v-list-item-subtitle class="caption"
              >{{ $t("email_notifications") }}:</v-list-item-subtitle
            >
            <div
              v-for="(item, index) in emailNotificationsExtract(
                value.referenceParameters.notificationValues,
                sharedData.emailNotificationLocales
              )"
              :key="index"
            >
              <v-list-item-title class="more-space">{{
                item.name
              }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.addressTo }} ({{ item.locale | locale }})
                {{ item.withAttachmentsText }}
              </v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-container>
</template>

<script>
import GsReadableTimeFrame from "@/components/GsReadableTimeFrame";
import GsCronjobToReadable from "@/components/GsCronjobToReadable";

export default {
  name: "GsReportSummary",
  components: {
    GsReadableTimeFrame,
    GsCronjobToReadable,
  },
  data() {
    return {
      value: null,
      sharedData: null,
      active: false,
    };
  },
  computed: {
    schedulingTimeData() {
      return {
        minute: this.value.minute,
        hour: this.value.hour,
        month: this.value.month,
        day: this.value.day,
        dayType: this.value.dayType,
      };
    },
    selectedAssetsExtract() {
      if (!this.sharedData.selectedTemplate) return null;
      if (
        !this.sharedData.selectedTemplate.inputParams.assetId &&
        !this.sharedData.selectedTemplate.inputParams.assetIds
      )
        return null;
      if (!this.sharedData.convertedInputParams) return null;

      return this.selectedAssetsAndItemsExtract(this.value.referenceParameters);
    },
    selectedDriversExtract() {
      if (!this.sharedData.selectedTemplate) return null;
      if (
        !this.sharedData.selectedTemplate.inputParams.driverId &&
        !this.sharedData.selectedTemplate.inputParams.driverIds
      )
        return null;
      if (!this.sharedData.convertedInputParams) return null;

      return this.selectedDriversAndItemsExtract(
        this.value.referenceParameters
      );
    },
    selectedGeozonesExtract() {
      if (!this.sharedData.selectedTemplate) return null;
      if (
        !this.sharedData.selectedTemplate.inputParams.geoZoneId &&
        !this.sharedData.selectedTemplate.inputParams.geoZoneIds
      )
        return null;
      if (!this.sharedData.convertedInputParams) return null;

      return this.selectedGeoZonesAndItemsExtract(
        this.value.referenceParameters
      );
    },
  },
  methods: {
    init(value, sharedData) {
      console.log("GsReportSummary: init", value);
      this.value = value;
      this.sharedData = sharedData;
      this.active = true;
    },
    stop() {
      console.log("GsReportSummary: stop");
      this.active = false;
      return this.cloneObj(this.value);
    },
    validate() {
      let error = null;
      return error;
    },
    getDayNames(days) {
      const tmp = days.split(",").map((item) => this.$t(`d${item}`));
      return tmp.join(", ");
    },
  },
};
</script>
