<template>
  <v-container fluid v-if="active">
    <v-row>
      <v-col cols="6" v-if="sharedData.dateStyle !== 'noDates'">
        <!-- in case of exact date picking -->
        <GsDatePicker
          :label="$t('date')"
          v-model="selectedDateFrom"
          :max="selectedDateTo"
          v-if="
            sharedData.convertedInputParams.startDate &&
            !sharedData.convertedInputParams.endDate &&
            !sharedData.needScheduling
          "
        />
        <GsDatePicker
          :label="$t('date_from')"
          v-model="selectedDateFrom"
          :max="selectedDateTo"
          v-if="
            sharedData.convertedInputParams.startDate &&
            sharedData.convertedInputParams.endDate &&
            !sharedData.needScheduling
          "
        />
        <GsDateTimePicker
          :label="$t('date_from')"
          v-model="selectedDateFrom"
          :max="selectedDateTo"
          v-if="
            sharedData.convertedInputParams.startDateTime &&
            sharedData.convertedInputParams.endDateTime &&
            !sharedData.needScheduling
          "
        />
        <GsDatePicker
          :label="$t('date_to')"
          v-model="selectedDateTo"
          :min="selectedDateFrom"
          v-if="
            sharedData.convertedInputParams.startDate &&
            sharedData.convertedInputParams.endDate &&
            !sharedData.needScheduling
          "
        />
        <GsDateTimePicker
          :label="$t('date_to')"
          v-model="selectedDateTo"
          :min="selectedDateFrom"
          v-if="
            sharedData.convertedInputParams.startDateTime &&
            sharedData.convertedInputParams.endDateTime &&
            !sharedData.needScheduling
          "
        />
        <!-- in case of time frame picking -->
        <GsTimeFramePicker
          :label="$t('start_from')"
          v-model="selectedFrameStart"
          v-if="
            sharedData.dateStyle == 'timeFrame' || sharedData.needScheduling
          "
        />
        <GsTimeFramePicker
          :label="$t('duration')"
          v-model="selectedFramePeriod"
          v-if="
            sharedData.dateStyle == 'timeFrame' || sharedData.needScheduling
          "
          :duration="true"
        />
        <div
          class="d-flex flex-row"
          v-if="sharedData.convertedInputParams.dailyTime"
        >
          <GsNumber
            :label="$t('dailyTime')"
            v-model="dailyTime"
            noLeadingZero
            :maxValue="maxHours"
          />
          <span class="ml-4 mt-5">{{ $t("hours") }}</span>
        </div>
        <GsCheckbox
          :label="$t('effectiveWork')"
          v-if="sharedData.convertedInputParams.effectiveWork"
          v-model="effectiveWork"
        />
      </v-col>
      <v-col cols="6">
        <!-- locale and timezone -->
        <GsLocaleSelector
          v-model="selectedLocale"
          :locales="sharedData.locales"
          v-if="sharedData.convertedInputParams.locale"
        />
        <GsTimezoneSelector
          v-model="selectedTimezone"
          v-if="sharedData.convertedInputParams.timezone"
          class="pt-5"
        />
        <GsCalendarType
          :label="$t('workDays')"
          v-if="sharedData.convertedInputParams.calendarType"
          v-bind="workDaysObject"
          @change="setCalendarType"
        />
      </v-col>
    </v-row>
    <template v-if="sharedData.needScheduling">
      <!-- <v-divider />
      <h3 class="mt-6 mb-4">
        <strong>{{ $t("repeat_details_label") }}:</strong>
      </h3> -->
      <v-text-field
        v-model="schedulingName"
        :label="$t('scheduling_name')"
        counter="256"
        maxlength="256"
        class="pb-0"
      />
      <v-textarea
        v-model="schedulingDescription"
        :label="$t('scheduling_description')"
        counter="1024"
        maxlength="1024"
        :rows="2"
        class="pb-0"
      />
      <GsDateTimePicker v-model="schedulingStartTime" :label="$t('start_at')" />
      <GsDateTimePicker
        v-model="schedulingEndTime"
        :label="$t('end_at')"
        :withEraser="true"
      />
      <GsRepeatScheduler
        v-model="schedulingTimeData"
        :startTime="schedulingStartTime"
        :label="$t('repeat_at')"
      />
      <v-card flat class="mb-2">
        <span class="overline">{{ $t("this_report_will_run_at") }}</span>
        <GsSchedulerSimulator
          :scheduleConfig="scheduleConfig"
          :compact="true"
        />
      </v-card>
    </template>
  </v-container>
</template>

<script>
import GsDatePicker from "@/components/GsDatePicker";
import GsDateTimePicker from "@/components/GsDateTimePicker";
import GsTimeFramePicker from "@/components/GsTimeFramePicker";
import GsLocaleSelector from "@/components/GsLocaleSelector";
import GsTimezoneSelector from "@/components/GsTimezoneSelector";
import GsRepeatScheduler from "@/components/GsRepeatScheduler";
import GsSchedulerSimulator from "@/components/GsSchedulerSimulator";
import GsNumber from "@/components/GsNumber";
import GsCheckbox from "@/components/GsCheckbox";
import GsCalendarType from "@/components/GsCalendarType";

export default {
  name: "GsReportParameters",
  components: {
    GsDatePicker,
    GsDateTimePicker,
    GsTimeFramePicker,
    GsLocaleSelector,
    GsTimezoneSelector,
    GsRepeatScheduler,
    GsSchedulerSimulator,
    GsNumber,
    GsCheckbox,
    GsCalendarType,
  },
  data() {
    return {
      maxHours: 24,
      selectedDateFrom: null,
      selectedDateTo: null,
      selectedFrameStart: null,
      selectedFramePeriod: null,
      selectedLocale: null,
      selectedTimezone: null,
      schedulingName: null,
      schedulingDescription: null,
      schedulingStartTime: null,
      schedulingEndTime: null,
      schedulingTimeData: null,
      active: false,
      effectiveWork: false,
      dailyTime: null,
      calendarType: null,
      calendar: null,
      normalWork: null,
    };
  },
  computed: {
    workDaysObject() {
      return {
        calendarType: this.calendarType,
        calendar: this.calendar,
        normalWork: this.normalWork,
      };
    },
    scheduleConfig() {
      console.log(
        "GsReportParameters - compute: scheduleConfig",
        this.schedulingTimeData.hour,
        this.schedulingStartTime
      );
      if (!this.schedulingStartTime || !this.selectedTimezone) return null;
      console.log("calendarType", this.calendarType);
      return {
        schedulingStartTime: this.schedulingStartTime,
        schedulingEndTime: this.schedulingEndTime,
        schedulingTimeData: this.schedulingTimeData,
        timezone: this.selectedTimezone,
        bDayCalendar:
          this.calendarType === 2
            ? "custom"
            : this.calendar
            ? this.calendar
            : "weekDays",
        bDays: this.normalWork,
      };
    },
  },
  methods: {
    init(value, sharedData) {
      console.log("GsReportParameters: init", { value, sharedData });
      this.value = value;
      this.sharedData = sharedData;
      this.setEditValue(this.value);
      this.active = true;
    },
    stop() {
      console.log("GsReportParameters: stop");
      this.active = false;
      return this.getEditValue();
    },
    getEditValue() {
      console.log("GsReportParameters: getEditValue");
      const v = this.cloneObj(this.value);

      v.referenceParameters.inputValues.startDate = this.selectedDateFrom;
      v.referenceParameters.inputValues.endDate = this.selectedDateTo;

      if (this.sharedData.dateStyle === "timeFrame") {
        v.referenceParameters.inputValues.timeFrame = {};
        v.referenceParameters.inputValues.timeFrame.startDate =
          this.selectedFrameStart;
        v.referenceParameters.inputValues.timeFrame.period =
          this.selectedFramePeriod;
      }

      v.referenceParameters.inputValues.locale = this.selectedLocale;
      v.referenceParameters.inputValues.timezone = this.selectedTimezone;

      if (this.sharedData.convertedInputParams.dailyTime) {
        v.referenceParameters.inputValues.dailyTime = this.dailyTime;
      }

      if (this.sharedData.convertedInputParams.effectiveWork) {
        v.referenceParameters.inputValues.effectiveWork = this.effectiveWork;
      }

      if (this.sharedData.convertedInputParams.calendarType) {
        v.referenceParameters.inputValues.calendarType = this.calendarType;
        v.referenceParameters.inputValues.calendar = this.calendar;
        v.referenceParameters.inputValues.normalWork = this.normalWork;
      }

      v.name = this.schedulingName;
      v.description = this.schedulingDescription;

      v.startTime = this.schedulingStartTime;
      v.endTime = this.schedulingEndTime;

      v.minute = this.schedulingTimeData.minute;
      v.hour = this.schedulingTimeData.hour;
      v.month = this.schedulingTimeData.month;
      v.day = this.schedulingTimeData.day;
      v.dayType = this.schedulingTimeData.dayType;

      v.bDayCalendar =
        this.calendarType === 2
          ? "custom"
          : this.calendar
          ? this.calendar
          : "weekDays";
      v.bDays = this.normalWork;

      return v;
    },
    setEditValue(v) {
      console.log("GsReportParameters: setEditValue", v);

      if (v == null) {
        this.selectedDateFrom = null;
        this.selectedDateTo = null;
        this.selectedFrameStart = null;
        this.selectedFramePeriod = null;
        this.selectedLocale = null;
        this.selectedTimezone = null;
        this.schedulingName = null;
        this.schedulingDescription = null;
        this.schedulingStartTime = null;
        this.schedulingEndTime = null;
        this.schedulingTimeData = null;
        this.effectiveWork = null;
        this.dailyTime = null;
        this.calendarType = null;
        this.calendar = null;
        this.normalWork = null;
        return;
      }

      this.selectedDateFrom = v.referenceParameters.inputValues.startDate;
      this.selectedDateTo = v.referenceParameters.inputValues.endDate;

      if (this.sharedData.dateStyle === "timeFrame") {
        this.selectedFrameStart = v.referenceParameters.inputValues.timeFrame
          ? v.referenceParameters.inputValues.timeFrame.startDate
          : null;
        this.selectedFramePeriod = v.referenceParameters.inputValues.timeFrame
          ? v.referenceParameters.inputValues.timeFrame.period
          : null;
      }

      if (v.referenceParameters.inputValues.locale) {
        this.selectedLocale = v.referenceParameters.inputValues.locale;
      } else {
        const defaultLocale = this.getDefaultLocale();
        if (this.sharedData.locales.find((p) => p.value === defaultLocale)) {
          this.selectedLocale = defaultLocale;
        } else if (this.sharedData.locales.length > 0) {
          this.selectedLocale = this.sharedData.locales[0].value;
        }
      }

      if (v.referenceParameters.inputValues.timezone) {
        this.selectedTimezone = v.referenceParameters.inputValues.timezone;
      } else {
        this.selectedTimezone = this.getDefaultTimeZone();
      }

      this.schedulingName = v.name;
      this.schedulingDescription = v.description;

      this.schedulingTimeData = {
        minute: v.minute,
        hour: v.hour,
        month: v.month,
        day: v.day,
        dayType: v.dayType,
      };
      this.schedulingStartTime = v.startTime;
      this.schedulingEndTime = v.endTime;

      if (this.sharedData.convertedInputParams.effectiveWork) {
        this.effectiveWork =
          typeof v.referenceParameters.inputValues.effectiveWork != "undefined"
            ? v.referenceParameters.inputValues.effectiveWork
            : false;
      }

      if (this.sharedData.convertedInputParams.dailyTime) {
        this.dailyTime =
          typeof v.referenceParameters.inputValues.dailyTime != "undefined"
            ? v.referenceParameters.inputValues.dailyTime
            : 8;
      }

      if (this.sharedData.convertedInputParams.calendarType) {
        this.calendarType =
          typeof v.referenceParameters.inputValues.calendarType != "undefined"
            ? v.referenceParameters.inputValues.calendarType
            : 1;
        this.calendar =
          typeof v.referenceParameters.inputValues.calendar != "undefined"
            ? v.referenceParameters.inputValues.calendar
            : "HU";
        this.normalWork =
          typeof v.referenceParameters.inputValues.normalWork != "undefined"
            ? v.referenceParameters.inputValues.normalWork
            : "1,2,3,4,5";
      }
    },
    validate() {
      if (
        this.sharedData.dateStyle !== "noDates" &&
        !this.sharedData.needScheduling
      ) {
        if (
          (this.sharedData.convertedInputParams.startDate ||
            this.sharedData.convertedInputParams.startDateTime) &&
          !this.sharedData.convertedInputParams.endDate &&
          !this.sharedData.convertedInputParams.endDateTime
        ) {
          if (!this.selectedDateFrom) {
            return this.$t("report_error_select_date");
          }
        }
        if (
          (this.sharedData.convertedInputParams.startDate ||
            this.sharedData.convertedInputParams.startDateTime) &&
          (this.sharedData.convertedInputParams.endDate ||
            this.sharedData.convertedInputParams.endDateTime)
        ) {
          if (!this.selectedDateFrom) {
            return this.$t("report_error_select_dates_from");
          }
          if (!this.selectedDateTo) {
            return this.$t("report_error_select_dates_to");
          }
        }
        // if (this.selectedDateFrom > this.selectedDateTo) {
        //   return this.$t("report_error_select_dates");
        // }
      }
      if (!this.selectedLocale) {
        return this.$t("report_error_select_locale");
      }
      if (!this.selectedTimezone) {
        return this.$t("report_error_select_timezone");
      }
      if (this.sharedData.needScheduling) {
        if (!this.schedulingName) {
          return this.$t("report_error_select_scheduling_name");
        }
        if (!this.schedulingStartTime) {
          return this.$t("report_error_select_scheduling_start");
        }
        if (!this.schedulingTimeData.dayType) {
          return this.$t("report_error_select_scheduling_start");
        }
      }
      if (this.sharedData.convertedInputParams.dailyTime && !this.dailyTime) {
        return this.$t("report_error_set_working_hours");
      }
      return null;
    },
    setCalendarType(newValues) {
      this.calendarType = newValues.calendarType;
      this.calendar = newValues.calendar;
      this.normalWork = newValues.normalWork;
    },
  },
};
</script>
