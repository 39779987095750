<template>
  <v-container fluid>
    <!-- <v-card flat class="mb-3"> -->
    <v-form class="mb-6" style="width: 80%" v-model="valid" ref="form">
      <v-card>
        <v-toolbar color="primary" dark>{{ $t("add_notification") }}</v-toolbar>
        <div class="pa-2">
          <div class="d-flex flex-row">
            <div style="width: 50%" class="mx-2">
              <v-text-field
                :label="$t('name')"
                v-model="formData.name"
                type="text"
                maxlength="256"
                :rules="formRules.required"
              ></v-text-field>
            </div>
            <div style="width: 50%" class="mx-2">
              <v-text-field
                :label="$t('email')"
                v-model="formData.email"
                type="text"
                maxlength="256"
                :rules="formRules.requiredEmail"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex flex-row mt-2">
            <div style="width: 50%" class="mx-2">
              <v-select
                :label="$t('language')"
                v-model="formData.templateId"
                :items="emailNotificationLocales"
                item-text="text"
                item-value="templateId"
                :rules="formRules.required"
                :no-data-text="$t('no_data')"
              >
              </v-select>
            </div>
            <div style="width: 50%" class="mx-2">
              <GsYesNoSelector
                v-model="formData.withAttachments"
                :label="$t('attach-reports')"
                :rules="formRules.required"
              >
              </GsYesNoSelector>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <!-- <v-card-actions> -->
          <v-btn class="ma-2" color="primary" small @click="addItem">
            <v-icon left>mdi mdi-plus</v-icon>
            {{ $t("add") }}
          </v-btn>
        </div>
        <!-- </v-card-actions> -->
      </v-card>
    </v-form>

    <v-row v-if="errorsText">
      <span class="subtitle-1 mb-4 red--text">{{ errorsText }}</span>
    </v-row>

    <!-- </v-card> -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :footer-props="{
          itemsPerPageText: $t('rows_per_page'),
          pageText: '{0}-{1} / {2}',
        }"
        :loading-text="$t('loading_data')"
        :no-data-text="$t('no_data')"
        :no-results-text="$t('no_result')"
        class="pa-0 ma-0"
        height="200px"
      >
        <!-- <template v-slot:item.templateType="{ item }">
					<div>
						<span>{{ item.$calculated.notification.templateType }}</span>
					</div>
				</template> -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="removeItem(item)">
            mdi mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.templateId="{ item }">
          <span>
            {{
              getLocaleByTemplateId(item.templateId, emailNotificationLocales)
                | locale
            }}
          </span>
        </template>
        <template v-slot:item.withAttachments="{ item }">
          <span>{{ item.withAttachments | toYesNo }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsYesNoSelector from "@/components/GsYesNoSelector";

export default {
  name: "GsNotificationsEditor",
  components: {
    GsYesNoSelector,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    emailNotificationLocales: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      valid: true,
      formData: {
        name: null,
        email: null,
        templateId: null,
        withAttachments: null,
      },
      formRules: {
        required: [
          (value) =>
            typeof value === "undefined" || value == null
              ? this.$t("field_required")
              : true,
        ],
        requiredEmail: [
          (value) =>
            typeof value === "undefined" || value == null
              ? this.$t("field_required")
              : this.validEmail(value)
              ? true
              : this.$t("valid_email_required"),
        ],
      },
      errors: [],
      headers: [
        { text: this.$t("name"), value: "name", align: "start" },
        { text: this.$t("email"), value: "email", align: "start" },
        { text: this.$t("language"), value: "templateId", align: "start" },
        {
          text: this.$t("attach-reports"),
          value: "withAttachments",
          align: "start",
        },
        { text: this.$t("actions"), value: "actions" },
      ],
    };
  },
  computed: {
    errorsText() {
      if (this.errors.length == 0) {
        return null;
      }
      return `${this.$t("required_at_tasks")}: ${this.errors.join(",")}`;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        this.items = this.parseInputValue(value);
      },
    },
  },
  mounted() {},
  methods: {
    async addItem() {
      this.errors = [];
      if (!this.validation()) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close"),
          },
        });
        return;
      }
      this.items.push({
        name: this.formData.name,
        email: this.formData.email.trim(),
        templateId: this.formData.templateId,
        withAttachments: this.formData.withAttachments,
      });
      this.emit();
      this.clearForm();
    },
    async removeItem(item) {
      const index = this.items.indexOf(item);
      if (index == -1) return;
      this.items.splice(index, 1);
      this.emit();
    },
    validation() {
      // this.errors = [];
      // if (!this.formData.name) {
      //   this.errors.push(this.$t("name"));
      // }
      // if (!this.formData.email) {
      //   this.errors.push(this.$t("email"));
      // } else if (!this.validEmail(this.formData.email)) {
      //   this.errors.push(this.$t("invalid_email"));
      // }
      // if (!this.formData.templateId) {
      //   this.errors.push(this.$t("language"));
      // }
      // return this.errors.length == 0;
      this.$refs.form.validate();
      return this.valid;
    },
    clearForm() {
      this.formData = {
        name: null,
        email: null,
        templateId: null,
        withAttachments: null,
      };
      this.$refs.form.reset();
      this.errors = [];
    },
    reset() {
      this.items = [];
      this.clearForm();
    },
    emit() {
      const value = this.getOutputValue(this.items);
      this.$emit("input", value);
    },
    parseInputValue(value) {
      if (value == null) return [];
      const channels = value.channels.filter((p) => p.type === "mail");
      if (channels.length == 0) return [];
      const convertedItems = channels[0].items;
      const items = convertedItems.map((p) => ({
        templateId: p.templateId,
        email: p.inputValues.items[0].address.to.email,
        name: p.inputValues.items[0].templateContext.name,
        withAttachments: p.inputValues.items[0].templateContext.withAttachments,
      }));
      return items;
    },
    getOutputValue(items) {
      const convertedItems = items.map((p) => ({
        templateId: p.templateId,
        inputValues: {
          items: [
            {
              address: {
                to: { email: p.email },
              },
              templateContext: {
                name: p.name,
                withAttachments: p.withAttachments,
              },
            },
          ],
        },
      }));

      const channels = [];
      if (convertedItems.length > 0) {
        channels.push({
          type: "mail",
          items: convertedItems,
        });
      }
      const result = channels.length > 0 ? { channels } : null;
      return result;
    },
  },
};
</script>
