<template>
  <v-container fluid v-if="active">
    <v-row class="my-2">
      <GsDatasourceSelector v-model="datasourceId" width="100%" class="px-8" />
    </v-row>
    <v-row v-if="datasourceId">
      <GsTemplateSelector2
        ref="templateSelector"
        :value="selectedTemplateId"
        @change="selectedTemplateId_change"
        @templates-loaded="setShowTemplateDesc"
        :datasourceId="datasourceId"
        class="px-8 pb-4"
        :height="height"
      />
    </v-row>
    <!-- <v-row v-if="showTemplateDesc">
      <GsTemplateInfo
        :sharedData="sharedData"
        class="px-8"
        style="width: 100%"
      />
    </v-row> -->
  </v-container>
</template>

<script>
import GsDatasourceSelector from "@/components/GsDatasourceSelector";
import GsTemplateSelector2 from "@/components/GsTemplateSelector2";
import GsTemplateInfo from "@/components/GsTemplateInfo";

export default {
  name: "GsReportTemplate",
  props: {
    height: {
      type: String,
      default: "300px",
    },
  },
  components: {
    GsDatasourceSelector,
    GsTemplateSelector2,
    GsTemplateInfo,
  },
  data() {
    return {
      value: null,
      sharedData: null,
      datasourceId: null,
      selectedTemplateId: null,
      active: false,
      showTemplateDesc: false,
    };
  },
  methods: {
    init(value, sharedData) {
      console.log("GsReportTemplate: init", value);
      this.value = value;
      this.sharedData = sharedData;
      this.active = true;
      this.setEditValue(this.value);
    },
    stop() {
      console.log("GsReportTemplate: stop");
      this.active = false;
      return this.getEditValue();
    },
    getEditValue() {
      console.log("GsReportTemplate: getEditValue");
      const v = this.cloneObj(this.value);

      if (v.referenceParameters.templateId !== this.selectedTemplateId) {
        // the assets, drivers pages will put inputValues back if newly selected template supports them...
        v.referenceParameters.inputValues = {
          queryParameters: [],
          items: [],
          additionalValues: {},
          // locale: this.getDefaultLocale(),
          // timezone: this.getDefaultTimeZone()
          locale: null,
          timezone: null,
        };
        v.referenceParameters.templateId = this.selectedTemplateId;
      }

      return v;
    },
    setEditValue(v) {
      console.log("GsReportTemplate: setEditValue", v);
      if (v == null) {
        this.selectedTemplateId = null;
        return;
      }
      this.selectedTemplateId = v.referenceParameters.templateId;
      this.updateSharedData(this.selectedTemplateId);
    },
    validate() {
      if (!this.datasourceId || !this.selectedTemplateId) {
        return this.$t("report_error_select_template");
      }
      // if (
      //   !this.sharedData.emailNotificationLocales ||
      //   this.sharedData.emailNotificationLocales.length === 0
      // ) {
      //   error = this.$t("missing_email_notification_locales");
      // }
      if (!this.sharedData.locales || this.sharedData.locales.length === 0) {
        return this.$t("missing_locales");
      }

      return null;
    },
    async selectedTemplateId_change(v) {
      this.selectedTemplateId = v;
      await this.updateSharedData(v);
    },
    async updateSharedData(newTemplateId) {
      // console.log("updateSharedData", newTemplateId)

      // const newTemplateId = newVal
      //   ? newVal.referenceParameters.templateId
      //   : null;
      // const oldTemplateId = oldVal
      //   ? oldVal.referenceParameters.templateId
      //   : null;
      // if (newTemplateId == oldTemplateId) return;

      console.log("GsReportCreator: updateSharedData...start");
      this.sharedData.updating = true;

      const selectedTemplate = await this.getTemplate(newTemplateId);

      //We have no template selected or template has been deleted
      if (newTemplateId == null || selectedTemplate == null) {
        this.sharedData.selectedTemplate = null;
        this.sharedData.convertedInputParams = null;
        this.sharedData.dateStyle = null;
        this.sharedData.emailNotificationLocales = [];
        this.sharedData.locales = [];
        this.sharedData.updating = false;
        console.log("GsReportCreator: updateSharedData...end", this.sharedData);
        return;
      }

      this.sharedData.selectedTemplate = selectedTemplate;
      this.sharedData.convertedInputParams = this.getConvertedInputParams(
        this.sharedData.selectedTemplate
      );

      this.sharedData.dateStyle = this.getDateStyle(
        this.sharedData.convertedInputParams,
        this.sharedData.needScheduling
      );

      try {
        this.sharedData.locales = await this.GetReportLocales(newTemplateId);
      } catch (err) {
        this.sharedData.locales = null;
        this.sendErrorReport(err);
      }

      try {
        this.sharedData.emailNotificationLocales =
          await this.GetEmailNotificationLocales(newTemplateId);
      } catch (err) {
        this.sharedData.emailNotificationLocales = null;
      }

      this.sharedData.updating = false;
      console.log("GsReportCreator: updateSharedData...end", this.sharedData);
    },
    getConvertedInputParams(selectedTemplate) {
      if (!selectedTemplate || !selectedTemplate.inputParams) {
        return {
          startDate: false,
          endDate: false,
          startDateTime: false,
          endDateTime: false,
          locale: true,
          timezone: false,
          assetId: false,
          assetIds: false,
          driverId: false,
          driverIds: false,
          geoZoneId: false,
          geoZoneIds: false,
          dailyTime: false,
          effectiveWork: false,
          calendarType: false,
          calendar: false,
          normalWork: false,
        };
      }

      return {
        startDate: selectedTemplate.inputParams.startDate?.type === "date",
        endDate: selectedTemplate.inputParams.endDate?.type === "date",
        startDateTime:
          selectedTemplate.inputParams.startDate?.type === "datetime",
        endDateTime: selectedTemplate.inputParams.endDate?.type === "datetime",
        locale: selectedTemplate.inputParams.locale?.type === "single-dropdown",
        timezone:
          selectedTemplate.inputParams.timezone?.type === "single-dropdown",
        assetId:
          selectedTemplate.inputParams.assetId?.type === "single-dropdown",
        assetIds:
          selectedTemplate.inputParams.assetIds?.type === "multi-dropdown",
        driverId:
          selectedTemplate.inputParams.driverId?.type === "single-dropdown",
        driverIds:
          selectedTemplate.inputParams.driverIds?.type === "multi-dropdown",
        geoZoneId:
          selectedTemplate.inputParams.geoZoneId?.type === "single-dropdown",
        geoZoneIds:
          selectedTemplate.inputParams.geoZoneIds?.type === "multi-dropdown",
        dailyTime:
          selectedTemplate.inputParams.dailyTime?.type === "value-input",
        effectiveWork:
          selectedTemplate.inputParams.effectiveWork?.type === "checkbox",
        calendarType:
          selectedTemplate.inputParams.calendarType?.type === "single-dropdown",
        calendar: selectedTemplate.inputParams.calendar?.type === "value-input",
        normalWork:
          selectedTemplate.inputParams.normalWork?.type === "value-input",
      };
    },
    getDateStyle(convertedInputParams, needScheduling) {
      if (!convertedInputParams) return "noDates";

      if (
        !convertedInputParams.startDate &&
        !convertedInputParams.endDate &&
        !convertedInputParams.startDateTime &&
        !convertedInputParams.endDateTime
      )
        return "noDates";

      return needScheduling ? "timeFrame" : "exactDates";
    },
    setShowTemplateDesc(value) {
      this.showTemplateDesc = value || false;
    },
  },
};
</script>
