<template>
  <v-container fluid class="pt-0">
    <v-row v-if="label != ''">
      <span class="caption" dense>{{ label }}</span>
    </v-row>
    <v-row>
      <v-col class="pl-0 pt-0 pb-0">
        <v-select
          :items="unit"
          item-text="name"
          item-value="value"
          class="body-2 test-locator-repeat-scheduler"
          :value="selectedUnit"
          @change="selectedUnit_change"
          dense
          single-line
          :no-data-text="$t('no_data')"
        >
          <template v-slot:item="{ item }">
            <span :label="item.name">{{ item.name }}</span>
          </template>
        </v-select>
      </v-col>
      <v-col class="pt-2 pb-0 text-center" v-if="selectedUnit !== 'year'">
        <span class="body-2">{{ $t("every") }}</span>
      </v-col>
      <!-- hourly -->
      <template v-if="selectedUnit == 'hour'">
        <v-col class="pl-0 pt-0 pb-0">
          <v-text-field
            dense
            :value="selectedQuantity"
            @change="selectedQuantity_change"
          />
        </v-col>
        <v-col class="pl-0 pt-2 pb-0">
          <span class="body-2">{{ $t("hours") }}</span>
        </v-col>
      </template>
      <!-- daily -->
      <template v-if="selectedUnit == 'day'">
        <v-col class="pl-0 pt-0 pb-0">
          <v-text-field
            dense
            :value="selectedQuantity"
            @change="selectedQuantity_change"
          />
        </v-col>
        <v-col class="pl-0 pt-2 pb-0">
          <span class="body-2">{{ $t("days") }}</span>
        </v-col>
      </template>
      <!-- weekly -->
      <template v-if="selectedUnit == 'week'">
        <v-col cols="12" class="pl-0 pt-0 pb-0">
          <v-row justify="space-around" class="pt-0 mt-0">
            <template v-for="(day, index) in weekDays">
              <v-checkbox
                dense
                :key="index"
                v-model="selectedDay"
                class="mr-2"
                :label="day.name"
                :value="day.value"
              />
            </template>
          </v-row>
        </v-col>
      </template>
      <!-- workPeriod -->
      <template v-if="selectedUnit == 'workPeriod'">
        <v-col class="pl-0 pt-0 pb-0">
          <v-text-field
            dense
            :value="selectedQuantity"
            @change="selectedQuantity_change"
          />
        </v-col>
        <v-col class="pt-2 pb-0">
          <span class="body-2">{{ $t("day_of_workPeriod") }}</span>
        </v-col>
      </template>
      <!-- restPeriod -->
      <template v-if="selectedUnit == 'restPeriod'">
        <v-col class="pl-0 pt-0 pb-0">
          <v-text-field
            dense
            :value="selectedQuantity"
            @change="selectedQuantity_change"
          />
        </v-col>
        <v-col class="pt-2 pb-0">
          <span class="body-2">{{ $t("day_of_restPeriod") }}</span>
        </v-col>
      </template>
      <!-- monthly -->
      <template v-if="selectedUnit == 'month'">
        <v-col class="pl-0 pt-0 pb-0">
          <v-text-field
            dense
            :value="selectedQuantity"
            @change="selectedQuantity_change"
          />
        </v-col>
        <v-col class="pt-2 pb-0">
          <span class="body-2">{{ $t("day_of_moth") }}</span>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "GsRepeatScheduler",
  props: {
    value: {
      type: Object,
      default: null,
    },
    startTime: {
      type: String,
      default: "20200101T00:00:00.000Z",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedUnit: null,
      selectedQuantity: 1,
      selectedDay: [],
      emitting: false,
      unit: [
        { name: this.$t("hourly"), value: "hour" },
        { name: this.$t("daily"), value: "day" },
        { name: this.$t("weekly"), value: "week" },
        { name: this.$t("workPeriod"), value: "workPeriod" },
        { name: this.$t("restPeriod"), value: "restPeriod" },
        { name: this.$t("monthly"), value: "month" },
        { name: this.$t("yearly"), value: "year" },
      ],
      weekDays: [
        { name: this.$t("mon"), value: 1 },
        { name: this.$t("tue"), value: 2 },
        { name: this.$t("wed"), value: 3 },
        { name: this.$t("thu"), value: 4 },
        { name: this.$t("fri"), value: 5 },
        { name: this.$t("sat"), value: 6 },
        { name: this.$t("sun"), value: 0 },
      ],
    };
  },
  watch: {
    startTime: {
      immediate: true,
      handler(value) {
        console.log("GsRepeatScheduler: watch startTime", value);
        if (this.selectedUnit == null) {
          console.log("GsRepeatScheduler: watch startTime prevented", value);
          return;
        }
        this.setDataset();
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(value, oldVal) {
        if (this.emitting) return;
        if (this.isEqualObj(value, oldVal)) return;
        console.log("GsRepeatScheduler: watch value", value);
        // if (
        //   !value ||
        //   !value.dayType ||
        //   !value.day ||
        //   !value.month ||
        //   !value.hour ||
        //   !value.minute
        // ) {
        //   this.setDataset();
        // }
        // weekly
        if (value.dayType === "dow") {
          this.selectedUnit = "week";
          if (value.day === "*") {
            this.selectedDay = [];
          } else {
            this.selectedDay = [];
            value.day.split(",").map((item) => {
              this.selectedDay.push(parseInt(item, 10));
            });
          }
        } else if (["dom", "dowp", "dorp"].includes(value.dayType)) {
          if (value.month !== "*") {
            // yearly
            this.selectedUnit = "year";
          } else if (value.hour.includes("*")) {
            // hourly
            this.selectedUnit = "hour";
            this.selectedQuantity =
              value.hour === "*" ? 1 : value.hour.split("*/").pop();
          } else if (value.day.includes("*")) {
            // daily
            this.selectedUnit = "day";
            this.selectedQuantity =
              value.day === "*" ? 1 : value.day.split("*/").pop();
          } else if (value.dayType === "dowp") {
            this.selectedUnit = "workPeriod";
            this.selectedQuantity = value.day != "" ? value.day : 1;
          } else if (value.dayType === "dorp") {
            this.selectedUnit = "restPeriod";
            this.selectedQuantity = value.day != "" ? value.day : 1;
          } else {
            // monthly
            this.selectedUnit = "month";
            this.selectedQuantity = value.day != "" ? value.day : 1;
          }
        }
      },
    },
    selectedDay: {
      immediate: true,
      handler() {
        if (this.emitting) return;
        this.setDataset();
      },
    },
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    isThisValid(value) {
      if (value < 1) {
        this.selectedQuantity = 1;
      }
    },
    selectedUnit_change(v) {
      this.selectedUnit = v;
      this.setDataset();
    },
    selectedQuantity_change(v) {
      this.selectedQuantity = v < 1 ? 1 : v;
      this.setDataset();
    },
    setDataset() {
      console.log("GsRepeatScheduler: setDataset", this.selectedUnit);
      const dataSet = {};
      switch (this.selectedUnit) {
        case "hour":
          dataSet.day = "*";
          dataSet.dayType = "dom";
          dataSet.month = "*";
          dataSet.hour =
            this.selectedQuantity === 1 ? "*" : `*/${this.selectedQuantity}`;
          dataSet.minute = dayjs(this.startTime).format("mm");
          break;
        case "day":
          dataSet.day =
            this.selectedQuantity === 1 ? "*" : `*/${this.selectedQuantity}`;
          dataSet.dayType = "dom";
          dataSet.month = "*";
          dataSet.hour = dayjs(this.startTime).format("HH");
          // dataSet.hour = dayjs.utc(this.startTime).format("HH");
          dataSet.minute = dayjs(this.startTime).format("mm");
          break;
        case "week":
          dataSet.day =
            this.selectedDay.length === 0 ? "*" : this.selectedDay.join(",");
          dataSet.dayType = "dow";
          dataSet.month = "*";
          dataSet.hour = dayjs(this.startTime).format("HH");
          dataSet.minute = dayjs(this.startTime).format("mm");
          break;
        case "workPeriod":
          dataSet.day = this.selectedQuantity;
          dataSet.dayType = "dowp";
          dataSet.month = "*";
          dataSet.hour = dayjs(this.startTime).format("HH");
          dataSet.minute = dayjs(this.startTime).format("mm");
          break;
        case "restPeriod":
          dataSet.day = this.selectedQuantity;
          dataSet.dayType = "dorp";
          dataSet.month = "*";
          dataSet.hour = dayjs(this.startTime).format("HH");
          dataSet.minute = dayjs(this.startTime).format("mm");
          break;
        case "month":
          dataSet.day =
            this.selectedQuantity !== "" ? `${this.selectedQuantity}` : "1";
          dataSet.dayType = "dom";
          dataSet.month = "*";
          dataSet.hour = dayjs(this.startTime).format("HH");
          dataSet.minute = dayjs(this.startTime).format("mm");
          break;
        case "year":
          dataSet.day = dayjs(this.startTime).format("DD");
          dataSet.dayType = "dom";
          dataSet.month = `${dayjs(this.startTime).format("MM")}/${
            this.selectedQuantity * 12
          }`;
          dataSet.hour = dayjs(this.startTime).format("HH");
          dataSet.minute = dayjs(this.startTime).format("mm");
          break;
      }
      console.log("GsRepeatScheduler: emit dataSet", dataSet);
      this.emitting = true;
      this.$emit("input", dataSet);
      this.$nextTick(() => {
        this.emitting = false;
      });
    },
  },
};
</script>
