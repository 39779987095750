<template>
  <v-checkbox :label="label" :input-value="value" @change="change" />
</template>

<script>
export default {
  name: "GsCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  methods: {
    change(inputValue) {
      this.$emit("input", !!inputValue);
    },
  },
};
</script>

<style scoped></style>
