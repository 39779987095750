<template>
  <v-text-field
    :value="editValue"
    @change="change"
    :key="newKey"
    :maxlength="maxlength"
    :label="label"
  >
    <v-btn slot="append" icon x-small rounded>
      <v-icon @click="up">mdi mdi-chevron-up</v-icon>
    </v-btn>
    <v-btn slot="append" icon x-small rounded>
      <v-icon @click="down">mdi mdi-chevron-down</v-icon>
    </v-btn>
  </v-text-field>
</template>

<script>
export default {
  name: "GsNumber",
  components: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    label: {
      type: String,
      default: null,
    },
    noLeadingZero: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    newKey: 0,
  }),
  computed: {
    editValue: {
      get() {
        if (this.noLeadingZero) {
          return this.value;
        }
        return `0${this.value}`.slice(-2);
      },
      set(newValue) {
        this.$emit(
          "input",
          Math.min(this.maxValue, Math.max(this.minValue, newValue))
        );
        if (newValue === this.value) {
          this.newKey = Math.floor(Math.random() * 100 * this.editValue);
        }
      },
    },
  },
  methods: {
    up() {
      this.editValue++;
    },
    down() {
      this.editValue--;
    },
    change(inputValue) {
      if (this.noLeadingZero) {
        this.editValue = inputValue;
      }
      this.editValue = parseInt(inputValue, 10);
    },
  },
};
</script>

<style scoped></style>
