<template>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        :value="dateFormatted"
        v-on="on"
        class="pb-2"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="currentValue"
      @input="dateSelected"
      :locale="locale"
      :min="min"
      :max="max"
      no-title
      :first-day-of-week="firstDayOfWeek"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "GsDatePicker",
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    locale: "en",
    firstDayOfWeek: 0,
    menuVisible: false,
    currentValue: null,
  }),
  computed: {
    dateFormatted() {
      if (this.currentValue == null) return null;
      return this.getFormattedDate(this.currentValue);
    },
  },
  watch: {
    value(v) {
      this.currentValue = v;
    },
  },
  created() {
    this.locale = this.$i18n.locale;
    this.firstDayOfWeek = dayjs().weekday(0).day();
    this.currentValue = this.value;
  },
  methods: {
    dateSelected(value) {
      this.currentValue = value;
      this.menuVisible = false;
      this.$emit("input", this.currentValue);
      this.$emit("change", this.currentValue);
    },
  },
};
</script>

<style scoped></style>
