<template>
  <v-card class="d-flex flex-column">
    <!-- <v-toolbar :color="sharedData.needScheduling ? 'lightYellow' : 'primary'">{{
      sharedData.needScheduling
        ? $t("scheduled_report_dialog_title")
        : $t("quick_report_dialog_title")
    }}</v-toolbar> -->
    <v-toolbar color="primary" dark>
      {{
        sharedData.needScheduling
          ? $t("scheduled_report_dialog_title")
          : $t("quick_report_dialog_title")
      }}
      <span v-if="sharedData.selectedTemplate">
        : {{ $t(`template_name.${sharedData.selectedTemplate.name}`) }} ({{
          sharedData.selectedTemplate.format
        }})
      </span>
    </v-toolbar>

    <v-stepper class="elevation-0" v-model="stepper">
      <v-stepper-header>
        <template v-for="step in visibleSteps">
          <v-stepper-step
            :key="step.index"
            :step="step.index"
            :complete="stepper > step.index"
            v-if="step.title"
          >
            {{ step.title }}
          </v-stepper-step>
          <v-divider
            v-if="step.index < visibleSteps[visibleSteps.length - 2].index"
            :key="`${step.index}-divider`"
          ></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <v-alert type="error" v-if="error" dense class="mb-0">
          <span>{{ error }}</span>
        </v-alert>
        <v-stepper-content
          v-for="step in visibleSteps"
          :key="step.index"
          :step="step.index"
          class="pa-0 ma-0"
        >
          <component
            v-bind:is="step.component"
            :ref="`screen_${step.index}`"
            :height="contentHeight"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-divider class="mt-auto"></v-divider>
    <v-card-actions class="d-flex pa-4">
      <v-btn @click="close_click" class="mr-auto close-button px-4" dark>
        <!-- <v-icon left>mdi-close</v-icon> -->
        {{ $t("close") }}
      </v-btn>
      <v-btn
        v-if="stepper > 1 && !isLastStep"
        color="primary"
        @click="back_click"
        class="px-4"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        v-if="!isLastStep"
        color="primary"
        @click="continue_click"
        :disabled="continue_disabled"
        :loading="continue_loading"
        class="px-4"
      >
        {{ $t("continue") }}
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
      <!-- <v-btn
        v-if="stepper === isLastStep"
        text
        width="75%"
        @click="reset"
      >
        {{ $t("create_another_report") }}
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import GsReportTemplate from "@/components/ReportCreator/GsReportTemplate";
import GsReportDataAssets from "@/components/ReportCreator/GsReportDataAssets";
import GsReportDataDrivers from "@/components/ReportCreator/GsReportDataDrivers";
import GsReportDataGeozones from "@/components/ReportCreator/GsReportDataGeozones";
import GsReportParameters from "@/components/ReportCreator/GsReportParameters";
import GsReportNotifications from "@/components/ReportCreator/GsReportNotifications";
import GsReportSummary from "@/components/ReportCreator/GsReportSummary";
import GsReportGeneration from "@/components/ReportCreator/GsReportGeneration";

export default {
  name: "GsReportCreator",
  props: {
    close: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    GsReportTemplate,
    GsReportDataAssets,
    GsReportDataDrivers,
    GsReportDataGeozones,
    GsReportParameters,
    GsReportNotifications,
    GsReportSummary,
    GsReportGeneration,
  },
  data() {
    return {
      steps: [
        {
          title: this.$t("instant_report_step_report_template"),
          component: "GsReportTemplate",
          visible: () => true,
        },
        {
          title: this.$t("instant_report_step_data_geozones"),
          component: "GsReportDataGeozones",
          visible: () =>
            this.sharedData.selectedTemplate &&
            (this.sharedData.selectedTemplate.inputParams.geoZoneId ||
              this.sharedData.selectedTemplate.inputParams.geoZoneIds),
        },
        {
          title: this.$t("instant_report_step_data_assets"),
          component: "GsReportDataAssets",
          visible: () =>
            this.sharedData.selectedTemplate &&
            (this.sharedData.selectedTemplate.inputParams.assetId ||
              this.sharedData.selectedTemplate.inputParams.assetIds),
        },
        {
          title: this.$t("instant_report_step_data_drivers"),
          component: "GsReportDataDrivers",
          visible: () =>
            this.sharedData.selectedTemplate &&
            (this.sharedData.selectedTemplate.inputParams.driverId ||
              this.sharedData.selectedTemplate.inputParams.driverIds),
        },
        {
          title: this.$t("instant_report_step_parameters"),
          component: "GsReportParameters",
          visible: () => true,
        },
        {
          title: this.$t("instant_report_step_notifications"),
          component: "GsReportNotifications",
          visible: () =>
            this.sharedData.emailNotificationLocales &&
            this.sharedData.emailNotificationLocales.length > 0,
        },
        {
          title: this.$t("instant_report_step_summary"),
          component: "GsReportSummary",
          visible: () => true,
        },
        {
          title: null,
          component: "GsReportGeneration",
          visible: () => true,
        },
      ],
      wizardData: null,
      sharedData: {
        needScheduling: false,
        edit: false,
        selectedTemplate: null,
        convertedInputParams: null,
        dateStyle: null,
        emailNotificationLocales: [],
        locales: [],
        updating: false,
      },

      stepper: 1,
      error: null,
      windowHeight: 600,
    };
  },
  // watch: {
  //   wizardData: {
  //     deep: true,
  //     immediate: true,
  //     async handler(newVal, oldVal) {
  //       // console.log("GsReportCreator: watch wizardData", newVal)
  //       if (this.isEqualObj(newVal, oldVal)) return;
  //       await this.updateSharedData(newVal, oldVal);
  //     }
  //   }
  // },
  computed: {
    contentHeight() {
      const heightPercentage = 40; // 50% of the screen height
      const adjustedHeight = this.windowHeight * (heightPercentage / 100);
      return `${adjustedHeight}px`;
    },
    visibleSteps() {
      return this.steps
        .filter((p) => p.visible())
        .map((p, i) => ({
          index: i + 1,
          title: p.title,
          component: p.component,
        }));
    },
    isFirstStep() {
      return this.stepper === this.visibleSteps[0].index;
    },
    isLastStep() {
      return (
        this.stepper === this.visibleSteps[this.visibleSteps.length - 1].index
      );
    },
    continue_disabled() {
      // if (
      //   // this.error ||
      //   !this.sharedData ||
      //   // !this.sharedData?.emailNotificationLocales?.length ||
      //   !this.sharedData?.locales?.length
      // ) {
      //   return true;
      // }
      // if (this.sharedData?.selectedTemplate) {
      //   return !Object.keys(this.sharedData?.selectedTemplate)?.length;
      // }

      if (this.sharedData.updating) return true;
      return false;
    },
    continue_loading() {
      if (this.sharedData.updating) return true;
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    create(initStartDate) {
      console.log("GsReportCreator: create", initStartDate);

      this.wizardData = {
        name: null,
        description: null,
        minute: null,
        hour: null,
        month: null,
        day: null,
        dayType: null,
        timezone: this.getDefaultTimeZone(),
        reference: `${process.env.VUE_APP_REPORT_MANAGER_API}/jobs`,
        referenceParameters: {
          templateId: null,
          inputValues: {
            queryParameters: [],
            items: [],
            // locale: this.getDefaultLocale(),
            // timezone: this.getDefaultTimeZone()
            locale: null,
            timezone: null,
          },
          notificationValues: null,
        },
        startTime: null,
        endTime: null,
        enabled: true,
      };
      this.sharedData.needScheduling = !!initStartDate;
      this.sharedData.edit = false;

      this.stepper = 1;
      let screen = this.getCurrentScreen();
      screen.init(this.wizardData, this.sharedData);

      this.error = null;
    },
    edit(task) {
      console.log("GsReportCreator: edit", task);

      this.wizardData = task;
      this.sharedData.needScheduling = true;
      this.sharedData.edit = true;

      this.stepper = 1;
      let screen = this.getCurrentScreen();
      screen.init(this.wizardData, this.sharedData);

      this.error = null;
    },
    continue_click() {
      let screen = this.getCurrentScreen();
      this.error = screen.validate();
      if (this.error) return;

      this.wizardData = screen.stop();

      this.stepper += 1;
      screen = this.getCurrentScreen();
      screen.init(this.wizardData, this.sharedData);
    },
    back_click() {
      this.error = null;
      if (this.stepper === 1) return;

      let screen = this.getCurrentScreen();

      this.wizardData = screen.stop();

      this.stepper -= 1;
      screen = this.getCurrentScreen();
      screen.init(this.wizardData, this.sharedData);
    },
    getCurrentScreen() {
      const key = `screen_${this.stepper}`;
      return this.$refs[key] ? this.$refs[key][0] : null;
    },
    close_click() {
      this.$emit("close", null);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>
