<template>
  <v-select
    :items="sortedLocales"
    :label="$t('locale_label')"
    :value="editValue"
    @change="change"
    :no-data-text="$t('no_data')"
  >
    <template v-slot:item="{ item }">
      <span :label="item.text">{{ item.text }}</span>
    </template>
  </v-select>
</template>

<script>
import { memory } from "ngt-frontend-core";

export default {
  name: "LocaleSelector",
  props: {
    value: {
      type: String,
      default: null,
    },
    locales: {
      type: Array,
      default() {
        return [
          { value: "en_US", text: this.$t("en_US"), legacy: "en" },
          // { value: "nb_NO", text: this.$t("nb_NO"), legacy: "nb" },
          { value: "hu_HU", text: this.$t("hu_HU"), legacy: "hu" },
        ];
      },
    },
  },
  data: () => ({
    editValue: null,
  }),
  computed: {
    sortedLocales() {
      if (!Array.isArray(this.locales)) return [];
      return this.locales
        .slice()
        .sort((a, b) => a.value.localeCompare(b.value));
    },
  },
  watch: {
    locales: {
      immediate: true,
      deep: true,
      handler(newLocales) {
        if (!newLocales.map((p) => p.value).includes(this.editValue)) {
          this.editValue = null;
        }
      },
    },
    value(v) {
      this.setEditValue(v);
    },
  },
  created() {
    this.setEditValue(this.value);
  },
  methods: {
    setEditValue(value) {
      this.editValue = value;
    },
    change(newValue) {
      // console.log(newValue)
      this.editValue = newValue;
      this.$emit("input", this.editValue);
      this.$emit("change", this.editValue);
    },
  },
};
</script>
