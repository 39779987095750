<template>
  <span>{{ displayText }}</span>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "GsCronjobToReadable",
  props: {
    cronObject: {
      type: Object,
      defualt() {
        return {};
      },
    },
  },
  data() {
    return {
      displayText: "",
    };
  },
  watch: {
    cronObject: {
      immediate: true,
      deep: true,
      handler(value) {
        if (Object.keys(Object(value)).length !== 0) {
          if (value.minute.includes("*")) {
            // minutely
            this.displayText =
              value.minute === "*"
                ? this.$t("in_every_minute")
                : `${this.$t("in_every")} ${parseInt(
                    value.minute.split("/").pop(),
                    10
                  )} ${this.$t("minute")}`;
          } else if (value.hour.includes("*")) {
            // hourly
            this.displayText =
              value.hour === "*"
                ? `${this.$t("in_every_hour")} `
                : `${parseInt(value.hour.split("/").pop(), 10)} ${this.$t(
                    "hourly_2"
                  )} `;
            this.displayText +=
              value.minute === "0"
                ? this.$t("at_start_of_hour")
                : `${this.$t("at_every")} ${parseInt(
                    value.minute,
                    10
                  )} ${this.$t("minute")}`;
          } else if (value.dayType === "dow") {
            // weekly
            this.displayText = `${this.$t("weekly_2")} `;
            if (value.day === "*") {
              this.displayText += this.$t("on_every_day");
            } else {
              const days = `${value.day}`.split(",").sort();
              const weekend = ["0", "6"];
              const workdays = ["1", "2", "3", "4", "5"];
              if (days.length == 7) {
                this.displayText += this.$t("on_every_day");
              } else if (days.every((val, index) => val === weekend[index])) {
                this.displayText += this.$t("on_weekends");
              } else if (days.every((val, index) => val === workdays[index])) {
                this.displayText += this.$t("on_workdays");
              } else {
                this.displayText += `${this.$t("on_every")} ${days.map((item) =>
                  dayjs(item, "d").format(" dddd")
                )}`;
              }
            }
            this.displayText += ` ${this.$t("at")} ${value.hour}:${
              value.minute
            }`;
          } else if (["dowp", "dorp"].includes(value.dayType)) {
            // weekly
            this.displayText = this.$t(`in_period_${value.dayType}`) + " ";
            if (value.day === "*") {
              this.displayText += this.$t("on_every_day");
            } else {
              const days = `${value.day}`.split(",").sort();
              const weekend = ["0", "6"];
              const workdays = ["1", "2", "3", "4", "5"];
              if (days.length === 7) {
                this.displayText += this.$t("on_every_day");
              } else if (days.length === 1) {
                this.displayText += `${days[0]} ${this.$t("day_on")}`;
              } else if (days.every((val, index) => val === weekend[index])) {
                this.displayText += this.$t("on_weekends");
              } else if (days.every((val, index) => val === workdays[index])) {
                this.displayText += this.$t("on_workdays");
              } else {
                this.displayText += `${this.$t("on_every")} ${days.map((item) =>
                  dayjs(item, "d").format(" dddd")
                )}`;
              }
            }
            this.displayText += ` ${this.$t("at")} ${value.hour}:${
              value.minute
            }`;
          } else {
            if (value.day.includes("*")) {
              // daily
              this.displayText =
                value.day === "*"
                  ? this.$t("every_day")
                  : `${this.$t("every")} ${dayjs(
                      value.day.split("/").pop(),
                      "D"
                    ).format("Do")} ${this.$t("days_2")}`;
            } else if (!value.month.includes("*")) {
              // yearly
              this.displayText = `${this.$t("yearly_2")} ${this.$t(
                "on"
              )} ${dayjs(value.month.split("/").shift(), "MM").format(
                "MMMM"
              )} ${value.day}`;
            } else {
              // monthly
              this.displayText = `${this.$t("monthly_2")} ${this.$t(
                "on"
              )} ${dayjs(value.day, "D").format("Do")} ${this.$t(
                "day_of_the_month"
              )}`;
            }
            this.displayText += ` ${this.$t("at")} ${value.hour}:${
              value.minute
            }`;
          }
        }
      },
    },
  },
};
</script>
