<template>
  <v-container class="py-1">
    <!-- <v-row v-if="!compact" class="pt-4">
      <v-list-item-subtitle>{{ $t("report_type") }}: {{ taskName }}</v-list-item-subtitle>
    </v-row>
    <v-divider /> -->
    <v-row>
      <v-list v-if="showFireTimes && !compact" class="pa-0">
        <template v-for="(item, index) in fireTimes">
          <v-list-item :key="index" class="py-0">
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="py-0">{{
                item.fireTime | getDisplayDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-if="showFireTimes && compact">
        <v-chip
          v-for="(item, index) in fireTimes"
          :key="index"
          :ripple="false"
          outlined
          class="mr-2"
          >{{ item.fireTime | getDisplayDate }}</v-chip
        >
      </template>
      <span
        v-if="!showFireTimes && loading"
        class="subtitle-1 mb-4 blue--text"
        >{{ $t("timer_simulation_loading") }}</span
      >
      <span
        v-if="!showFireTimes && !loading"
        class="subtitle-1 mb-4 red--text"
        >{{ $t("timer_simulation_issue") }}</span
      >
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";

const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsSchedulerSimulator",
  props: {
    taskName: {
      type: String,
      default: "",
    },
    scheduleConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    iterationLimit: {
      type: Number,
      default: 5,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFireTimes: false,
      loading: true,
      fireTimes: [],
    };
  },
  watch: {
    scheduleConfig: {
      deep: true,
      immediate: true,
      handler(value, oldValue) {
        // console.log("GsSchedulerSimulator: watch scheduleConfig");
        if (this.isEqualObj(value, oldValue)) {
          // console.log("GsSchedulerSimulator: watch scheduleConfig prevented");
          return;
        }
        this.scheduleConfig_changed(value);
      },
    },
  },
  methods: {
    async scheduleConfig_changed(value) {
      this.fireTimes = [];
      this.showFireTimes = false;
      this.loading = false;

      if (
        Object.keys(Object(value)).length === 0 ||
        typeof value.schedulingTimeData.day === "undefined" ||
        typeof value.schedulingTimeData.dayType === "undefined" ||
        typeof value.schedulingTimeData.hour === "undefined" ||
        typeof value.schedulingTimeData.minute === "undefined" ||
        typeof value.schedulingTimeData.month === "undefined"
      )
        return;

      try {
        this.loading = true;
        const result = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/_service/task-invocations`,
          method: "POST",
          data: {
            minute: value.schedulingTimeData.minute,
            hour: value.schedulingTimeData.hour,
            month: value.schedulingTimeData.month,
            day: `${value.schedulingTimeData.day}`,
            dayType: value.schedulingTimeData.dayType,
            bDayCalendar: value.bDayCalendar,
            bDays: value.bDays,
            timezone: value.timezone,
            startTime: value.schedulingStartTime,
            endTime: value.schedulingEndTime,
            iterationLimit: this.iterationLimit,
          },
        });

        this.fireTimes = [];
        result.data.map((item) => {
          this.fireTimes.push({
            fireTime: dayjs(item.fireTime).format("YYYY-MM-DD HH:mm"),
          });
        });
        this.showFireTimes = true;
        this.loading = false;
      } catch (error) {
        this.fireTimes = [];
        this.showFireTimes = false;
        this.loading = false;
        this.errorSnackbar(error);
      }
    },
  },
};
</script>
