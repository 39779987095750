<template>
  <v-container fluid @mouseleave="setHoverItem(null)">
    <div class="caption text--secondary">
      {{ $t("report_type") }}
    </div>
    <v-treeview
      activatable
      :items="items"
      :open.sync="openedNodeIds"
      :active.sync="activeNodeIds"
      dense
      hoverable
      :style="{ height: height, overflow: 'auto' }"
    >
      <!-- <template v-slot:prepend="{ item }">
        <div
          @mouseenter="setHoverItem(item.id, 0)"
          @click="onNodeLabelselected(item, $event)"
        >
          &nbsp;
        </div>
      </template> -->
      <template v-slot:label="{ item }">
        <div
          @mouseenter="setHoverItem(item.id, 0)"
          @click="onNodeLabelselected(item, $event)"
        >
          <div v-if="item.type === 'group'">
            <span> {{ item.text }} </span>
          </div>

          <div v-if="item.type === 'template'" class="pb-2">
            <div>
              {{ item.text }}
            </div>
            <div
              class="caption pl-2 font-weight-light"
              style="line-height: 0.8rem"
            >
              {{ item.desc }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:append="{ item, leaf, active }">
        <div
          @mouseenter="setHoverItem(item.id, 1)"
          @click="onNodeLabelselected(item, $event)"
          style="min-width: 100px; text-align: end"
        >
          &nbsp;
          <v-btn-toggle
            v-model="item.selectedFormatIndex"
            v-if="leaf && (active || item.hover)"
            dense
            mandatory
          >
            <v-btn
              v-for="(format, index) in item.formats"
              :key="index"
              @click="onFormatselected(item, index, $event)"
              @mouseenter="setHoverItem(item.id, 2)"
            >
              <v-icon :color="showReportFormatColor(format.format)">
                {{ showReportFormat(format.format) }}
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
    </v-treeview>
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
const notificationsEnums = require("@/app-data/notifications-enums.json");

export default {
  name: "GsTemplateSelector2",
  props: {
    value: {
      type: String,
      default: null,
    },
    datasourceId: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: "300px",
    },
  },
  components: {},
  data() {
    return {
      groups: [],
      availableTemplates: [],
      items: [],
      openedNodeIds: [],
      activeNodeIds: [],
      selectedTemplateId: null,
    };
  },
  computed: {},
  watch: {
    value(newVal, oldVal) {
      if (this.isEqualObj(newVal, oldVal)) return;
      this.setEditValue(newVal);
    },
    async datasourceId() {
      await this.getTemplates();
    },
  },
  async created() {
    this.setEditValue(this.value);
    await this.getTemplates();
  },
  methods: {
    setEditValue(v) {
      this.selectedTemplateId = v;
    },
    async getTemplates() {
      if (this.datasourceId == null) {
        this.availableTemplates = [];
        return;
      }
      try {
        const url = `/templates?filter=datasourceId:eq:${this.datasourceId},status:eq:${notificationsEnums.notificationTemplateStatus}&sort=name:asc&fields=name,description,inputParams,tags,format&limit=100`;
        const result = await callBffAPI({
          url,
          method: "GET",
          cache: true,
        });
        this.availableTemplates = [];
        for (const template of result.data) {
          await this.calculateProperties(template);
          this.availableTemplates.push(template);
        }

        this.groups = this.getGroups();

        this.items = this.getItems();

        // if (this.availableTemplates.length === 1) {
        //   this.onNodeselected({ id: template.templateId });
        // }

        // Open the group of selected template
        // const selectedGroup = this.getGroupByTemplateId(
        //   this.selectedTemplateId
        // );
        // if (selectedGroup) {
        //   this.openedNodeIds = this.groups.indexOf(selectedGroup);
        // }

        // Open all groups
        this.openedNodeIds = this.items.map((p) => p.id);

        // Activate the node of selected template
        const { node, index } = this.getNodeByTemplateId(
          this.selectedTemplateId
        );
        if (node) {
          this.activeNodeIds = [node.id];
          node.selectedFormatIndex = index;
        }

        this.$emit("templates-loaded", !!result?.data?.length);
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async calculateProperties(template) {
      // template.$calculated = template.$calculated || {};
      // template.tags = this.$t("default_template_group");
    },
    getGroups() {
      let tags = this.availableTemplates.map((p) => p.tags);
      tags = tags.flat();
      tags = tags.filter((p) =>
        p.startsWith("role:reportManager:template:group")
      );
      tags = this.distinct(tags);
      return tags;
    },
    getItems() {
      let items = [];
      for (let [index, group] of this.groups.entries()) {
        items.push({
          id: `GROUP_${index}`,
          type: "group",
          name: group,
          text: this.$t(group.substring(group.lastIndexOf(":") + 1)),
          children: this.getChildrenOfGroup(group),
        });
      }
      return items;
    },
    getItemsOfGroup(group) {
      return this.availableTemplates.filter((p) => p.tags.includes(group));
    },
    getChildrenOfGroup(group) {
      let children = [];
      let items = this.getItemsOfGroup(group);
      for (let item of items) {
        const existing = children.find((p) => p.name === item.name);
        if (existing) {
          existing.formats.push({
            templateId: item.templateId,
            format: item.format,
          });
          existing.formats = this.arrSortAlphabetically(existing.formats, {
            key: "format",
          });
          continue;
        }

        children.push({
          id: `TEMPLATE_${item.name}`,
          type: "template",
          name: item.name,
          formats: [
            {
              templateId: item.templateId,
              format: item.format,
            },
          ],
          text: this.$t(`template_name.${item.name}`),
          desc: this.$t(`template_desc.${item.name}`),
          selectedFormatIndex: 0,
          hover: false,
        });
      }

      children = this.arrSortAlphabetically(children, { key: "text" });

      return children;
    },
    onFormatselected(node, formatIndex, event) {
      event.stopPropagation();

      this.activeNodeIds = [node.id];

      const templateId = node.formats[formatIndex].templateId;
      // console.log("selected: ", templateId)

      this.doFormatSelected(templateId);
    },
    doFormatSelected(templateId) {
      if (this.selectedTemplateId === templateId) return;
      this.selectedTemplateId = templateId;
      this.$emit("input", this.selectedTemplateId);
      this.$emit("change", this.selectedTemplateId);
    },
    getGroupByTemplateId(templateId) {
      if (!templateId) return null;
      const selectedTemplate = this.availableTemplates.find(
        (p) => p.templateId === templateId
      );
      if (!selectedTemplate) return null;
      const tags = selectedTemplate.tags.filter((p) =>
        p.startsWith("role:reportManager:template:group")
      );
      if (tags.length < 1) return null;
      return tags[0];
    },
    getNodeByTemplateId(templateId) {
      for (let item of this.items) {
        for (let node of item.children) {
          const index = node.formats.findIndex(
            (p) => p.templateId === templateId
          );
          if (index !== -1) {
            return { node, index };
          }
        }
      }
      return { node: null, index: null };
    },
    getNodeById(nodeId) {
      for (let item of this.items) {
        if (item.id === nodeId) {
          return item;
        }
        for (let node of item.children) {
          if (node.id === nodeId) {
            return node;
          }
        }
      }
      return null;
    },
    reset() {
      this.selectedTemplateId = null;
    },
    onNodeLabelselected(node, event) {
      // console.log("onNodeLabelselected", node.id)

      // Set default format
      if (node.type === "template") {
        const formatIndex = 0;
        const templateId = node.formats[formatIndex].templateId;
        this.doFormatSelected(templateId);
      }

      // Group open / close on group title click
      if (node.type === "group") {
        // Close Group
        if (this.openedNodeIds.includes(node.id)) {
          const index = this.openedNodeIds.indexOf(node.id);
          this.openedNodeIds.splice(index, 1);
          return;
        }

        // Open Group
        this.openedNodeIds.push(node.id);

        this.doFormatSelected(null);
      }
    },
    setHoverItem(nodeId, source) {
      // console.log(source)
      for (let item of this.items) {
        for (let node of item.children) {
          node.hover = node.id === nodeId;
        }
      }
    },
  },
};
</script>

<style>
.v-treeview-node {
  cursor: pointer;
}
</style>
