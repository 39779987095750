import { render, staticRenderFns } from "./GsReadableTimeFrame.vue?vue&type=template&id=3fe9a136"
import script from "./GsReadableTimeFrame.vue?vue&type=script&lang=js"
export * from "./GsReadableTimeFrame.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports