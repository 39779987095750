<template>
  <v-select
    :value="selectedDatasource"
    :items="datasources"
    :label="$t('data_source')"
    @change="onChange"
    item-value="datasourceId"
    class="test-locator-datasource"
    hide-details
    :no-data-text="$t('no_data')"
  >
    <template v-slot:selection="{ item }">
      <v-avatar size="16px" class="mx-2">
        <v-img :src="downloadImg(item.name)" contain />
      </v-avatar>
      <span>{{ item.name }}</span>
    </template>
    <template v-slot:item="{ item }">
      <v-avatar size="16px" class="mx-2">
        <v-img :src="downloadImg(item.name)" contain />
      </v-avatar>
      <span :label="item.name">{{ item.name }}</span>
    </template>
  </v-select>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
const defaultImg = require("@/assets/scopes/default.png");

export default {
  name: "GsDatasourceSelector",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      datasources: [],
    };
  },
  computed: {
    selectedDatasource() {
      this.autoSelectFirst();
      return this.datasources.find((p) => p.datasourceId == this.value);
    },
  },
  async created() {
    await this.getDatasources();
  },
  methods: {
    downloadImg(fileName) {
      try {
        // console.log(fileName)
        return require("@/assets/scopes/" + fileName + ".png");
      } catch {
        return defaultImg;
      }
    },
    async getDatasources() {
      try {
        const result = await callBffAPI({
          url: `/datasources?filter=status:eq:datasource.status.active&sort=name:asc`,
          method: "GET",
          cache: true,
        });
        this.datasources = result.data;
        this.autoSelectFirst();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    onChange(datasourceId) {
      this.$emit("input", datasourceId);
    },
    autoSelectFirst() {
      if (!this.value && this.datasources.length) {
        this.onChange(this.datasources[0].datasourceId);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
