<template>
  <div>
    <v-alert
      border="left"
      colored-border
      :type="errorMessage ? 'error' : 'info'"
      elevation="1"
      :color="errorMessage ? 'error' : 'info'"
    >
      <h3 class="subtitle-1 mb-2" v-if="this.sharedData.updating">
        {{ $t(`loading`) }}
      </h3>
      <h3
        class="subtitle-1 mb-2"
        v-if="!errorMessage && !this.sharedData.updating"
      >
        {{ $t(`template_name.${templateInfo.name}`) }}
      </h3>
      <p class="subtitle-2" v-if="!errorMessage && !this.sharedData.updating">
        <!-- {{ templateInfo.description }} -->
        {{ $t(`template_desc.${templateInfo.name}`) }}
      </p>
      <p v-if="errorMessage">{{ $t("template_loading_error") }}</p>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "GsTemplateInfo",
  props: {
    sharedData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      templateInfo: null,
    };
  },
  computed: {
    errorMessage() {
      if (!this.sharedData.selectedTemplate) {
        return null;
      }
      if (this.sharedData.updating) {
        return null;
      }
      // if (
      //   !this.sharedData.emailNotificationLocales ||
      //   this.sharedData.emailNotificationLocales.length === 0
      // ) {
      //   return this.$t("missing_email_notification_locales");
      // }
      if (!this.sharedData.locales || this.sharedData.locales.length === 0) {
        return this.$t("missing_locales");
      }
      return null;
    },
  },
  watch: {
    sharedData: {
      deep: true,
      handler(value) {
        this.update();
      },
    },
  },
  created() {
    this.update();
  },
  methods: {
    update() {
      this.templateInfo = this.sharedData.selectedTemplate;
      if (this.templateInfo == null) {
        this.templateInfo = {
          name: "choose_template",
          description: "",
        };
      }
    },
  },
};
</script>
