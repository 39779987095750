<template>
  <v-container fluid class="pb-0 pt-0 mt-1">
    <v-row>
      <v-col cols="18" class="pl-0 pt-0 pb-0">
        <GsDatePicker v-model="date" :label="label" />
      </v-col>
      <v-col cols="2" class="pl-0 pt-0 pb-0">
        <GsNumber v-model="hours" :minValue="0" :maxValue="23" :maxlength="2" />
      </v-col>
      <v-col cols="1" class="pt-8 pl-0 pt-0 pb-0" style="text-align: center">
        <span>:</span>
      </v-col>
      <v-col cols="2" class="pa-0">
        <GsNumber
          v-model="minutes"
          :minValue="0"
          :maxValue="59"
          :maxlength="2"
        />
      </v-col>
      <v-col cols="1" v-if="withEraser">
        <v-icon class="ma-2 pa-0" @click="eraser_click">mdi mdi-close</v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GsDatePicker from "@/components/GsDatePicker";
import GsNumber from "@/components/GsNumber";
import dayjs from "dayjs";

export default {
  name: "GsDateTimePicker",
  components: {
    GsDatePicker,
    GsNumber,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: null,
    },
    withEraser: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date: null,
    hours: 0,
    minutes: 0,
  }),
  computed: {},
  watch: {
    date() {
      this.emitValue();
    },
    hours() {
      this.emitValue();
    },
    minutes() {
      this.emitValue();
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setValue(newValue);
        }
      },
    },
  },
  //   created() {
  //     this.setValue();
  //   },
  methods: {
    setValue(value) {
      const dt = dayjs(value);
      this.date = dt.format("YYYY-MM-DD");
      this.hours = dt.hour();
      this.minutes = dt.minute();
    },
    emitValue() {
      if (this.date == null || this.hours == null || this.minutes == null) {
        this.$emit("input", null);
        return;
      }
      const dt = dayjs(this.date)
        .set("hour", this.hours)
        .set("minute", this.minutes);
      this.$emit("input", dt.toISOString());
    },
    eraser_click() {
      this.date = null;
      this.hours = 0;
      this.minutes = 0;
      this.emitValue();
    },
  },
};
</script>

<style scoped>
.test {
  background-color: antiquewhite;
}
</style>
