import { render, staticRenderFns } from "./GsTemplateSelector2.vue?vue&type=template&id=628bd461"
import script from "./GsTemplateSelector2.vue?vue&type=script&lang=js"
export * from "./GsTemplateSelector2.vue?vue&type=script&lang=js"
import style0 from "./GsTemplateSelector2.vue?vue&type=style&index=0&id=628bd461&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports