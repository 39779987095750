<template>
  <span class="text-lowercase">{{ display }}</span>
</template>

<script>
export default {
  name: "GsReadableTimeFrame",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    duration: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      display: null,
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!Array.isArray(value) || !value.length) {
          this.display = this.$t("no_time_frame_picked");
        } else {
          this.display = "";
          if (this.duration) {
            if (value[0]?.quantity) {
              this.display = `${value[0].quantity} ${this.$t(value[0].unit)}`;
            } else {
              this.display = `${
                value[0].method === "startOf"
                  ? this.$t("start_of")
                  : this.$t("end_of")
              } ${this.$t("actual")} ${this.$t(value[0].unit)}`;
            }
          } else {
            value.forEach((item) => {
              if (item?.quantity) {
                this.display = `${item.quantity} ${this.$t(item.unit)} ${
                  item.method === "subtract"
                    ? this.$t("before")
                    : this.$t("after")
                } ${this.display}`;
              } else {
                this.display += `${this.$t("actual")} `;
                item.method === "startOf"
                  ? (this.display += `${this.$t("start_of")} ${this.$t(
                      item.unit
                    )}`)
                  : (this.display += `${this.$t("end_of")} ${this.$t(
                      item.unit
                    )}`);
              }
            });
          }
        }
      },
    },
  },
};
</script>
