<template>
  <v-container fluid v-if="active">
    <GsItemSelector :config="config" v-if="config" v-model="selection" />
  </v-container>
</template>

<script>
import { GsItemSelector } from "ngt-item-selector-lib";
import { i18n } from "@/locales/i18n";
import { apiOpsBff } from "ngt-frontend-core";

export default {
  name: "GsReportDataGeozones",
  components: {
    GsItemSelector,
  },
  data() {
    return {
      value: null,
      sharedData: null,
      selection: {
        items: [],
        groups: [],
      },
      active: false,
      config: null,
    };
  },
  computed: {},
  methods: {
    init(value, sharedData) {
      // console.log("GsReportDataGeozones: init", { value, sharedData });
      this.value = value;
      this.sharedData = sharedData;

      let keyField = "_idMap";
      if (
        this.sharedData.selectedTemplate.inputParams.geoZoneIds &&
        this.sharedData.selectedTemplate.inputParams.geoZoneIds.keyField
      )
        keyField =
          this.sharedData.selectedTemplate.inputParams.geoZoneIds.keyField;
      if (
        this.sharedData.selectedTemplate.inputParams.geoZoneId &&
        this.sharedData.selectedTemplate.inputParams.geoZoneId.keyField
      )
        keyField =
          this.sharedData.selectedTemplate.inputParams.geoZoneId.keyField;

      // console.log("sharedData: ", this.sharedData)
      this.config = {
        queryFilters: [
          // {
          //   fieldName: "groupTokens",
          //   type: "selecttree",
          //   title: "filters.group.title",
          //   placeholder: "filters.group.placeholder",
          //   description: "",
          //   icon: "mdi-format-list-group",
          //   query: "/groups",
          //   queryFields: ["groupToken", "name"],
          //   querySort: [{ field: "groupToken", direction: "asc" }],
          //   queryFilters: [],
          // },
          {
            fieldName: "geoZoneTypeId",
            type: "select",
            title: "filters.geoZoneType.title",
            placeholder: "filters.geoZoneType.placeholder",
            description: "",
            icon: "mdi-map-marker",
            clearable: false,
            required: true,
            query: "/geo-zone-types",
            queryFields: ["_uri", "zoneTypeName"],
            querySort: [
              { field: "isSystemType", direction: "asc" },
              { field: "zoneTypeName", direction: "asc" },
            ],
            queryFilters: [],
            selectFirst: true,
          },
          {
            fieldName: "zoneName",
            type: "text",
            title: "search",
            placeholder: "",
            description: "",
            icon: "mdi-magnify",
            clearable: true,
            required: false,
          },
        ],
        items: {
          type: "geozone",
          query: "/geo-zones",
          querySort: [{ field: "zoneName", direction: "asc" }],
          queryFields: ["geoZoneId", "_idMap", "zoneName"],
          keyName: "geoZoneId",
          // keyField: "_idMap",
          keyField,
          displayMapping: [["zoneName", "both"]],
        },
        groups: {
          query: "/groups",
          queryFields: ["groupToken", "name"],
          querySort: [{ field: "groupToken", direction: "asc" }],
          selectionType: "leaf",
        },
        multiSelect: this.sharedData.convertedInputParams.geoZoneIds,
        allItemsSelection: this.sharedData.convertedInputParams.geoZoneIds,
        dataConnector: apiOpsBff.callAPI,
        t: (key) => i18n.t(key),
        appSetting: "reports",
        appSettingKey: "geoZone",
      };

      this.active = true;
      this.setEditValue(this.value);
    },
    stop() {
      // console.log("GsReportDataGeozones: stop");
      this.active = false;
      return this.getEditValue();
    },
    getEditValue() {
      // console.log("GsReportDataGeozones: getEditValue");

      const v = this.cloneObj(this.value);

      // console.log("Before Geozones v.referenceParameters.inputValues.queryParameters", v.referenceParameters.inputValues.queryParameters);
      // console.log("Before Geozones v.referenceParameters.inputValues.items", v.referenceParameters.inputValues.items);

      const queryParameters = v.referenceParameters.inputValues.queryParameters;
      const items = v.referenceParameters.inputValues.items;

      // remove old queryParameters, items
      const indexOfQueryParameters = queryParameters.findIndex((p) =>
        ["geoZoneIds.geoZoneId", "geoZoneIds", "geoZoneId"].includes(p)
      );
      if (indexOfQueryParameters !== -1) {
        queryParameters.splice(indexOfQueryParameters, 1);
        items.splice(indexOfQueryParameters, 1);
      }

      // add new queryParameters, items
      if (this.sharedData.convertedInputParams.geoZoneIds) {
        // multiselect
        queryParameters.push("geoZoneIds.geoZoneId");
        items.push({
          // TODO: instead of below, use the commented-out part when change to new input values
          // geoZoneIds: this.selection.items,
          items: this.selection.items,
          groups: this.selection.groups,
        });
      }
      if (this.sharedData.convertedInputParams.geoZoneId) {
        // single select
        queryParameters.push("geoZoneId");
        // TODO: instead of below, use the commented-out part when change to new input values
        // items.push(
        //   this.selection.items.length === 1 ? this.selection.items[0] : null
        // );
        items.push({
          items: this.selection.items,
          groups: [],
        });
      }

      v.referenceParameters.inputValues.items = items;
      v.referenceParameters.inputValues.queryParameters = queryParameters;

      // console.log("After Geozones v.referenceParameters.inputValues.queryParameters", v.referenceParameters.inputValues.queryParameters);
      // console.log("After Geozones v.referenceParameters.inputValues.items", v.referenceParameters.inputValues.items);

      return v;
    },
    setEditValue(v) {
      this.selection = this.selectedGeoZonesAndItems(v.referenceParameters);
      console.log("GsReportDataGeozones: setEditValue", this.selection);
    },
    validate() {
      let error = null;
      if (
        this.selection.items.length === 0 &&
        this.selection.groups.length === 0
      ) {
        error = this.$t("report_error_select_geozones");
      }
      return error;
    },
  },
};
</script>
