<template>
  <v-select
    :value="editValue"
    @change="onChange"
    :items="options"
    style="width: 100%"
    :menu-props="{
      bottom: true,
      offsetY: true,
    }"
    :disabled="disabled"
    :label="label"
    :rules="rules"
    :no-data-text="$t('no_data')"
  >
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [
        {
          text: this.$t("yes"),
          value: true,
        },
        {
          text: this.$t("no"),
          value: false,
        },
      ],
      editValue: null,
    };
  },
  watch: {
    value() {
      this.editValue = this.value;
    },
  },
  created: function () {
    this.editValue = this.value;
  },
  methods: {
    onChange(value) {
      this.editValue = value;
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>
