<template>
  <v-container fluid v-if="active">
    <GsEmailNotificationsEditor
      v-model="notificationValues"
      :emailNotificationLocales="sharedData.emailNotificationLocales"
    />
  </v-container>
</template>

<script>
import GsEmailNotificationsEditor from "@/components/GsEmailNotificationsEditor";

export default {
  name: "GsReportNotifications",
  components: {
    GsEmailNotificationsEditor,
  },
  data() {
    return {
      value: null,
      sharedData: null,
      notificationValues: null,
      active: false,
    };
  },
  methods: {
    init(value, sharedData) {
      console.log("GsReportNotifications: init", value);
      this.value = value;
      this.sharedData = sharedData;
      this.active = true;
      this.setEditValue(this.value);
    },
    stop() {
      console.log("GsReportNotifications: stop");
      this.active = false;
      return this.getEditValue();
    },
    getEditValue() {
      console.log("GsReportNotifications: getEditValue");
      const v = this.cloneObj(this.value);
      v.referenceParameters.notificationValues = this.notificationValues;
      return v;
    },
    setEditValue(v) {
      console.log("GsReportNotifications: setEditValue", v);
      if (v == null) {
        this.notificationValues = null;
        return;
      }
      this.notificationValues = v.referenceParameters.notificationValues;
    },
    validate() {
      let error = null;
      return error;
    },
  },
};
</script>
