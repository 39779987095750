<template>
  <v-container fluid class="pt-2 pb-3 pr-6">
    <v-row v-if="label != ''">
      <span class="caption dense">{{ label }}</span>
    </v-row>
    <v-row>
      <v-col cols="4" class="pl-0 pt-0" v-if="!duration">
        <v-select
          :items="position"
          item-text="name"
          item-value="value"
          class="body-2"
          v-model="selectedPosition"
          dense
          single-line
          :no-data-text="$t('no_data')"
        />
      </v-col>
      <v-col cols="4" class="pl-0 pt-0" v-if="!duration">
        <v-select
          :items="method"
          item-text="name"
          item-value="value"
          class="body-2"
          v-model="selectedMethod"
          dense
          single-line
          :no-data-text="$t('no_data')"
        />
      </v-col>
      <v-col cols="4" class="pl-0 pt-0" v-if="duration">
        <v-select
          :items="durationPosition"
          item-text="name"
          item-value="value"
          class="body-2"
          v-model="selectedDurationPosition"
          dense
          single-line
          :no-data-text="$t('no_data')"
        />
      </v-col>
      <v-col cols="1" class="pl-0 pt-0" v-if="isThisVisible">
        <v-text-field
          v-model="selectedQuantity"
          dense
          :value="quantity"
          @change="isThisValid"
        />
      </v-col>
      <v-col cols="3" class="pl-0 pt-0 pr-0">
        <v-select
          :items="unit"
          item-text="name"
          item-value="value"
          class="body-2"
          v-model="selectedUnit"
          dense
          single-line
          :no-data-text="$t('no_data')"
        />
      </v-col>
    </v-row>
    {{ setTimeFrame }}
  </v-container>
</template>
<script>
import { ref } from "vue";

export default {
  name: "GsTimeFramePicker",
  props: {
    label: {
      type: String,
      default: "",
    },
    quantity: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
  },
  setup(props) {
    const selectedPosition = ref("startOf");
    const selectedDurationPosition = ref("startOf");
    const selectedMethod = ref("subtract");
    const selectedQuantity = ref(1);
    const selectedUnit = ref("day");

    if (Array.isArray(props.value) && props.value.length > 0) {
      if (props.duration) {
        const selectDuration = props.value[0];

        if (selectDuration.method === "add") {
          selectedUnit.value = selectDuration.unit;
          selectedQuantity.value = selectDuration.quantity;
        } else {
          selectedUnit.value = selectDuration.unit;
          selectedDurationPosition.value = selectDuration.method;
        }
      } else {
        const selectedStartDatePosition = props.value[0];

        selectedPosition.value = selectedStartDatePosition.method;
        selectedUnit.value = selectedStartDatePosition.unit;

        if (props.value[1]) {
          selectedMethod.value = props.value[1].method;
          selectedUnit.value = props.value[1].unit;
          selectedQuantity.value = props.value[1].quantity;
        }
      }
    }

    return {
      selectedPosition,
      selectedDurationPosition,
      selectedMethod,
      selectedQuantity,
      selectedUnit,
    };
  },
  watch: {
    duration: {
      immediate: true,
      deep: true,
      handler(value) {
        const timeFrame = [];

        if (value) {
          if (this.selectedDurationPosition === "exactly") {
            if (["workPeriod", "restPeriod"].includes(this.selectedUnit)) {
              this.selectedUnit = "day";
            }

            timeFrame.push({
              method: "add",
              unit: `${this.selectedUnit}`,
              quantity: this.selectedQuantity,
            });
          } else {
            timeFrame.push({
              method: this.selectedDurationPosition,
              unit: this.selectedUnit,
            });
          }
        } else {
          timeFrame.push({
            method: this.selectedPosition,
            unit: this.selectedUnit,
          });
          if (this.selectedMethod !== "current") {
            timeFrame.push({
              method: this.selectedMethod,
              unit: `${this.selectedUnit}`,
              quantity: this.selectedQuantity,
            });
          }
        }
        this.$emit("input", timeFrame);
      },
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (Array.isArray(newValue)) {
          this.setValue(newValue, this.duration);
        }
      },
    },
  },
  computed: {
    position() {
      return [
        { name: this.$t("start_of"), value: "startOf" },
        { name: this.$t("end_of"), value: "endOf" },
      ];
    },
    durationPosition() {
      return [
        { name: this.$t("start_of"), value: "startOf" },
        { name: this.$t("end_of"), value: "endOf" },
        { name: this.$t("exactly"), value: "exactly" },
      ];
    },
    unit() {
      return [
        { name: this.$t("day"), value: "day" },
        { name: this.$t("week"), value: "week" },
        { name: this.$t("workPeriod"), value: "workPeriod" },
        { name: this.$t("restPeriod"), value: "restPeriod" },
        { name: this.$t("month"), value: "month" },
        { name: this.$t("year"), value: "year" },
      ].filter(
        (u) =>
          !["workPeriod", "restPeriod"].includes(u.value) ||
          this.selectedDurationPosition !== "exactly"
      );
    },
    method() {
      return [
        { name: this.$t("previous"), value: "subtract" },
        { name: this.$t("current"), value: "current" },
        { name: this.$t("next"), value: "add" },
      ];
    },
    setTimeFrame() {
      const timeFrame = [];
      if (this.duration) {
        if (this.selectedDurationPosition === "exactly") {
          const unit = ["workPeriod", "restPeriod"].includes(this.selectedUnit)
            ? "day"
            : this.selectedUnit;

          timeFrame.push({
            method: "add",
            quantity: this.selectedQuantity,
            unit: `${unit}`,
          });
        } else {
          timeFrame.push({
            method: this.selectedDurationPosition,
            unit: this.selectedUnit,
          });
        }
      } else {
        timeFrame.push({
          method: this.selectedPosition,
          unit: this.selectedUnit,
        });
        if (this.selectedMethod !== "current") {
          timeFrame.push({
            method: this.selectedMethod,
            quantity: this.selectedQuantity,
            unit: `${this.selectedUnit}`,
          });
        }
      }

      this.$emit("input", timeFrame);
      return null;
    },
    isThisVisible() {
      if (!this.duration && this.selectedMethod !== "current") {
        return true;
      }
      if (this.duration && this.selectedDurationPosition === "exactly") {
        return true;
      }
      return false;
    },
  },
  methods: {
    setValue(value, duration) {
      if (Array.isArray(value) && value.length > 0) {
        if (duration) {
          const selectDuration = value[0];

          if (selectDuration.method === "add") {
            this.selectedUnit = selectDuration.unit;
            this.selectedQuantity = selectDuration.quantity;
            this.selectedDurationPosition = "exactly";
          } else {
            this.selectedUnit = selectDuration.unit;
            this.selectedDurationPosition = selectDuration.method;
          }
        } else {
          const selectedStartDatePosition = value[0];

          this.selectedPosition = selectedStartDatePosition.method;
          this.selectedUnit = selectedStartDatePosition.unit;

          if (value[1]) {
            this.selectedMethod = value[1].method;
            this.selectedUnit = value[1].unit;
            this.selectedQuantity = value[1].quantity;
          }
        }
      }
    },
    isThisValid(value) {
      if (value < 1) {
        this.selectedQuantity = 1;
      }
    },
  },
};
</script>
