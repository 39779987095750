<template>
  <v-container fluid class="pt-4">
    <v-row>
      <span class="caption text--secondary" dense>{{ $t("workDays") }}</span>
    </v-row>
    <v-row dense>
      <v-col dense>
        <v-radio-group
          dense
          v-model="selectedCalendarType"
          mandatory
          @change="update"
        >
          <!-- 1 -->
          <v-radio :label="$t('by_calendar')" :value="1"></v-radio>
          <v-select
            :items="allCalendars"
            item-text="name"
            item-value="value"
            class="mb-3"
            v-model="selectedCalendar"
            dense
            single-line
            :disabled="selectedCalendarType === 2"
            @change="update"
            :no-data-text="$t('no_data')"
          />
          <!-- 2 -->
          <v-radio :label="$t('custom_calendar')" :value="2"></v-radio>
          <div class="d-flex">
            <v-checkbox
              v-for="(day, index) in allDays"
              :key="index"
              :label="day.name"
              :disabled="selectedCalendarType === 1"
              :input-value="getActualState(day.value)"
              dense
              class="gs-calendar-type"
              @change="updateDays(day.value)"
            ></v-checkbox>
          </div>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GsCalendarType",
  props: {
    calendarType: {
      type: Number,
      default: 1,
    },
    calendar: {
      type: String,
      default: "HU",
    },
    normalWork: {
      type: String,
      default: "1,2,3,4,5",
    },
  },
  data() {
    return {
      allCalendars: [],
      allDays: [],
      selectedCalendarType: 1,
      selectedCalendar: "HU",
      selectedDays: [],
    };
  },
  watch: {
    calendarType: {
      immediate: true,
      handler(val) {
        this.selectedCalendarType = val;
      },
    },
    calendar: {
      immediate: true,
      handler(val) {
        this.selectedCalendar = val;
      },
    },
    normalWork: {
      immediate: true,
      handler(val) {
        this.selectedDays = val.split(",");
      },
    },
  },
  computed: {
    selectedDaysString() {
      return this.selectedDays.join(",");
    },
  },
  created() {
    this.allCalendars = [
      {
        value: "HU",
        name: this.$t("hungary"),
      },
    ];
    const days = [];
    for (let i = 1; i <= 7; i++) {
      days.push({ value: `${i}`, name: this.$t(`d${i}`) });
    }
    this.allDays = days;
  },
  methods: {
    getActualState(index) {
      if (this.selectedDays.indexOf(index) === -1) {
        return false;
      }
      return true;
    },
    update() {
      const emitObject = {
        calendarType: this.selectedCalendarType,
        calendar: this.selectedCalendar,
        normalWork: this.selectedDaysString,
      };
      this.$emit("change", emitObject);
    },
    updateDays(val) {
      const index = this.selectedDays.indexOf(val);
      if (index === -1) {
        this.selectedDays.push(val);
      } else {
        this.selectedDays.splice(index, 1);
      }
      this.update();
    },
  },
};
</script>

<style>
.gs-calendar-type {
  margin-right: 0.4rem;
}
.gs-calendar-type .v-input--selection-controls__input {
  margin-right: 0;
}
</style>
