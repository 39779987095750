<template>
  <v-container fluid v-if="active">
    <GsItemSelector :config="config" v-if="config" v-model="selection" />
  </v-container>
</template>

<script>
import { GsItemSelector } from "ngt-item-selector-lib";
import { i18n } from "@/locales/i18n";
import { apiOpsBff } from "ngt-frontend-core";

export default {
  name: "GsReportDataAssets",
  components: {
    GsItemSelector,
  },
  data() {
    return {
      value: null,
      sharedData: null,
      selection: {
        items: [],
        groups: [],
      },
      active: false,
      config: null,
    };
  },
  computed: {},
  methods: {
    init(value, sharedData) {
      console.log("GsReportDataAssets: init", value, sharedData);
      this.value = value;
      this.sharedData = sharedData;

      let keyField = "_idMap";
      if (
        this.sharedData.selectedTemplate.inputParams.assetIds &&
        this.sharedData.selectedTemplate.inputParams.assetIds.keyField
      )
        keyField =
          this.sharedData.selectedTemplate.inputParams.assetIds.keyField;
      if (
        this.sharedData.selectedTemplate.inputParams.assetId &&
        this.sharedData.selectedTemplate.inputParams.assetId.keyField
      )
        keyField =
          this.sharedData.selectedTemplate.inputParams.assetId.keyField;
      console.log(keyField);

      // console.log("sharedData: ", this.sharedData)
      this.config = {
        queryFilters: [
          {
            fieldName: "groupTokens",
            type: "selecttree",
            title: "filters.group.title",
            placeholder: "filters.group.placeholder",
            description: "",
            icon: "mdi-format-list-group",
            clearable: true,
            required: false,
            query: "/groups",
            queryFields: ["groupToken", "name"],
            querySort: [{ field: "groupToken", direction: "asc" }],
            queryFilters: [],
            selectFirst: false,
          },
          {
            fieldName: "assetCode",
            type: "text",
            title: "search",
            placeholder: "",
            description: "",
            icon: "mdi-magnify",
            clearable: true,
            required: false,
          },
        ],
        items: {
          type: "asset",
          query: "/assets",
          querySort: [{ field: "assetCode", direction: "asc" }],
          queryFields: [
            "assetId",
            "_idMap",
            "assetCode",
            "assetName",
            "assetTypeName",
            "brand",
            "model",
          ],
          keyName: "assetId",
          // keyField: "_idMap",
          keyField,
          displayMapping: [
            ["assetCode", "both"],
            ["assetName", "both"],
            ["brand", "ui"],
            ["model", "ui"],
            // ["assetTypeName", "both"]
          ],
        },
        groups: {
          query: "/groups",
          queryFields: ["groupToken", "name"],
          querySort: [{ field: "groupToken", direction: "asc" }],
          selectionType: "leaf",
        },
        multiSelect: this.sharedData.convertedInputParams.assetIds,
        allItemsSelection: this.sharedData.convertedInputParams.assetIds,
        dataConnector: apiOpsBff.callAPI,
        t: (key) => i18n.t(key),
        appSetting: "reports",
        appSettingKey: "asset",
      };

      this.active = true;
      this.setEditValue(this.value);
    },
    stop() {
      console.log("GsReportDataAssets: stop");
      this.active = false;
      return this.getEditValue();
    },
    getEditValue() {
      console.log("GsReportDataAssets: getEditValue");

      const v = this.cloneObj(this.value);

      // console.log("Before Assets v.referenceParameters.inputValues.queryParameters", v.referenceParameters.inputValues.queryParameters);
      // console.log("Before Assets v.referenceParameters.inputValues.items", v.referenceParameters.inputValues.items);

      const queryParameters = v.referenceParameters.inputValues.queryParameters;
      const items = v.referenceParameters.inputValues.items;

      // remove old queryParameters, items
      const indexOfQueryParameters = queryParameters.findIndex((p) =>
        ["assetIds.assetId", "assetIds", "assetId"].includes(p)
      );
      if (indexOfQueryParameters !== -1) {
        queryParameters.splice(indexOfQueryParameters, 1);
        items.splice(indexOfQueryParameters, 1);
      }

      // add new queryParameters, items
      if (this.sharedData.convertedInputParams.assetIds) {
        // multiselect
        queryParameters.push("assetIds.assetId");
        items.push({
          // TODO: instead of below, use the commented-out part when change to new input values
          // assetIds: this.selection.items,
          items: this.selection.items,
          groups: this.selection.groups,
        });
      }
      if (this.sharedData.convertedInputParams.assetId) {
        // singleselect
        queryParameters.push("assetId");
        // TODO: instead of below, use the commented-out part when change to new input values
        // items.push(this.selection.items.length === 1 ? this.selection.items[0] : null);
        items.push({
          items: this.selection.items,
          groups: [],
        });
      }

      v.referenceParameters.inputValues.items = items;
      v.referenceParameters.inputValues.queryParameters = queryParameters;

      // console.log("After Assets v.referenceParameters.inputValues.queryParameters", v.referenceParameters.inputValues.queryParameters);
      // console.log("After Assets v.referenceParameters.inputValues.items", v.referenceParameters.inputValues.items);

      return v;
    },
    setEditValue(v) {
      this.selection = this.selectedAssetsAndItems(v.referenceParameters);
      console.log("GsReportDataAssets: setEditValue", this.selection);
    },
    validate() {
      let error = null;
      if (
        this.selection.items.length === 0 &&
        this.selection.groups.length === 0
      ) {
        error = this.$t("report_error_select_assets");
      }
      return error;
    },
  },
};
</script>
